import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { useAppSelector } from '@/hooks.ts';
import { conditionalCTR, conditionalEPV, conditionalRPM, visitsAndClicks } from '@/utils/conditionalColumns.tsx';
import { useMemo } from 'react';
import { EstimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-premium';
import { CurrencyComparator } from '@/utils/tables-utils.tsx';
import { formatCurrency } from '@/utils/formatNumbers.ts';

const getDomain = (url: string): string => {
	try {
		if (url.includes('://p.') || url.includes('://l.')) {
			return ' - ' + url.split('.')[1];
		}
		return '';
	} catch (e) {
		return '';
	}
};

export const getOttoPresellName = (url: string): string => {
	try {
		const partsOfPresellUrl = url.split('//')[1].split('/');
		const domain = getDomain(url);
		if (partsOfPresellUrl[2]) {
			return `${partsOfPresellUrl[2].split('?')[0]}${domain}`;
		} else {
			return `${partsOfPresellUrl[1].split('?')[1]}${domain}`;
		}
	} catch (e) {
		return url;
	}
};

const columns = (isOtto: boolean) =>
	[
		{
			field: 'url',
			headerName: 'Presell URL',
			width: 200,
			renderCell: (params: GridRenderCellParams) => (
				<a target="_blank" className="manage-name-link" href={params.value} rel="noreferrer">
					{isOtto ? getOttoPresellName(params.value) : params.value}
				</a>
			),
		},
		...commonColumns([
			'ctr',
			'visits',
			'clicks',
			'conversions',
			'epc',
			'spend',
			'revenue',
			'profit',
			'profit_percent',
			'cvr',
			'rpm',
		]),
		{
			field: 'epv',
			headerName: 'EPV',
			width: 100,
			type: 'number',
			sortComparator: CurrencyComparator,
			renderCell: params => formatCurrency(params.value),
		},
	] satisfies GridColDef[];

export default function Presells() {
	const { estimatedSpends, isOtto, advancedTracking, maximusLinks } = useAppSelector(state => state.campaignDetails);

	const asyncColumns = useMemo(() => {
		return {
			...conditionalCTR(advancedTracking, maximusLinks),
			...visitsAndClicks(true, advancedTracking, maximusLinks),
			...conditionalRPM(advancedTracking, maximusLinks),
			...conditionalEPV(advancedTracking, maximusLinks),
		};
	}, [advancedTracking, maximusLinks]);

	return (
		<div className="campaign-details-report">
			{!estimatedSpends && <EstimatedReportsDisclaimer />}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns(isOtto)}
				tableName="Presells"
				endpoint="api/v1/campaign/reporting/presell"
				aggregationModel={{
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					cpc: 'avg',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					maximusRpm: 'maximusRpm',
					cvr: 'avg',
					epc: 'avg',
					epv: 'avg',
					rpm: 'rpm',
					spend: 'sum',
					revenue: 'sum',
					profit: 'sum',
					profit_percent: 'profitPercent',
					presell_ctr: 'avg',
					presell_url: 'blankColumn',
				}}
			/>
		</div>
	);
}
