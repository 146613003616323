import PageLoader from '@/Components/Loaders/PageLoader';
import Login from '@/Components/Pages/Login';
import { useAppSelector } from '@/hooks';
import Logout from '@/Pages/logout';
import VersionChecker from '@/Routing/VersionChecker';
import useAuth from '@/utils/useAuth';
import useInitializeApp from '@/utils/useInitializeApp';
import { useEffect } from 'react';
import { Route, Routes } from 'react-router';
import SocialDashboard from '@/Components/Pages/SocialDashboard';
import AIGeneration from '@/Pages/ai_generation.tsx';
import VideoSwap from '@/Pages/video_swap.tsx';

export const SocialRoutes = () => {
	const { appLoaded } = useAppSelector(state => state.main);
	const { theme } = useAppSelector(state => state.main.userSettings);
	useAuth();
	useInitializeApp();

	useEffect(() => {
		document.body.className = theme;
	}, [theme]);

	return !appLoaded ? (
		<PageLoader />
	) : (
		<>
			<Routes>
				<Route index element={<SocialDashboard />} />
				<Route key="/login" path="/login" element={<Login />} />
				<Route key="/logout" path="/logout" element={<Logout />} />
				<Route key="/ai_generation" path="/ai_generation" element={<AIGeneration />} />
				<Route key="/video_swap" path="/video_swap" element={<VideoSwap />} />
			</Routes>
			<VersionChecker />
		</>
	);
};
