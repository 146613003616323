import { GridAggregationFunction } from '@mui/x-data-grid-premium';

export const rpm = (estimated = false): GridAggregationFunction<{ visits: number; revenue: number }, number> => ({
	label: '',
	getCellValue: ({ row }) => ({
		visits: row.visits,
		revenue: estimated ? row.maximusRevenue : row.revenue,
	}),
	apply: ({ values }) => {
		let visits = 0;
		let revenue = 0;
		values.forEach(value => {
			if (value) {
				visits += Number(value.visits);
				revenue += Number(value.revenue);
			}
		});
		if (!visits) {
			return 0;
		}
		return (revenue / visits) * 1000;
	},
	columnTypes: ['string', 'number'],
});
