import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import HelpIcon from '@/Icons/Help.tsx';
import MenuIcon from '@/Icons/MenuIcon';
import RefreshIcon from '@/Icons/Refresh';
import RightArrow from '@/Icons/RightArrow.tsx';
import UserIcon from '@/Icons/UserIcon.tsx';
import { refreshAll, updateRefreshedAt } from '@/Redux/Slices/filters.ts';
import { updateSidebar } from '@/Redux/Slices/main';
import { updateMainStore } from '@/Redux/Slices/main.ts';
import IssueReport from '@/utils/reporting/IssueReport';
import { useAuth0 } from '@auth0/auth0-react';
import { FormControl, IconButton, Menu, MenuItem, Select, SelectChangeEvent, Tooltip, Typography } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import './Appbar.css';

function RefreshButton() {
	const [spin, setSpin] = useState(false);
	const dispatch = useAppDispatch();
	const refresh = () => {
		dispatch(refreshAll(true));
		dispatch(updateRefreshedAt());
		setSpin(true);
		setTimeout(() => {
			setSpin(false);
		}, 2500);
	};
	return (
		<IconButton onClick={refresh} size="small" id="refresh-button" disabled={spin}>
			<RefreshIcon className={`size-[30px] fill-white/80 ${spin ? 'animate-spin opacity-50' : ''}`} />
		</IconButton>
	);
}

function HelpPageIcon() {
	const navigate = useNavigate();
	const redirect = () => {
		navigate('/help/1-getting-started/1-welcome-to-maximus-support');
	};
	return (
		<IconButton onClick={redirect} size="small" id="help-page-button">
			<HelpIcon className="size-[30px] fill-white/80" />
		</IconButton>
	);
}

function AddCampaignDropdown() {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const redirectPath = (event: SelectChangeEvent) => {
		navigate(`/add_campaign_${event.target.value}`);
	};
	return (
		<FormControl
			className={`appbar-select-form-control ${pathname.includes('add_campaign_') ? 'appbar-select-form-control-active' : ''}`}
			variant="standard"
			sx={{ minWidth: 120 }}
		>
			<Select value="-1" onChange={redirectPath} label="User" name="select-add-campaign">
				<MenuItem disabled value="-1">
					Add Campaign
				</MenuItem>
				<MenuItem value="taboola">Taboola</MenuItem>
				<MenuItem value="revcontent">RevContent</MenuItem>
				<MenuItem value="outbrain">Outbrain</MenuItem>
			</Select>
		</FormControl>
	);
}

function UserSettingsDropdown() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { version, branch, isSocialUser, isAdminUser } = useAppSelector(state => state.main);
	const [anchorEl, setAnchorEl] = useState<Element | null>(null);
	const open = Boolean(anchorEl);
	const isProd = process.env.NODE_ENV === 'production';
	const handleClick = (event: MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const switchView = () => {
		const newVal = !isSocialUser;
		dispatch(updateMainStore({ key: 'isSocialUser', value: newVal }));
		localStorage.setItem('adminSocialView', newVal.toString());
		navigate('/');
	};
	return (
		<>
			<IconButton
				onClick={handleClick}
				size="small"
				aria-controls={open ? 'user-settings-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				className="text-white opacity-80 transition hover:opacity-100"
				id="user-settings-menu-button"
			>
				<UserIcon className="size-7 rounded-full border-2 border-solid border-white fill-white p-px" />
			</IconButton>
			<Menu
				id="user-settings-menu"
				MenuListProps={{
					'aria-labelledby': 'user-settings-menu-button',
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{isAdminUser && (
					<MenuItem divider onClick={switchView}>
						<RightArrow className="mr-2 size-5 fill-black/80" />
						{isSocialUser ? ' Native View' : ' Social View'}
					</MenuItem>
				)}
				<MenuItem divider onClick={() => navigate('/logout')}>
					Logout
				</MenuItem>
				<MenuItem dense>
					<div className="max-w-72 opacity-70">
						<p>Maximus version: {version}</p>
						{!isProd && (
							<Tooltip title={branch}>
								<Typography noWrap variant="inherit" className="border-t">
									Branch "{branch}"
								</Typography>
							</Tooltip>
						)}
					</div>
				</MenuItem>
			</Menu>
		</>
	);
}

function AppbarLink({ link, render }: { link: string; render: string }) {
	const { pathname } = useLocation();
	return (
		<Link
			className={twMerge(
				'flex h-[calc(100%-12px)] items-center justify-center rounded-t-md border-b-[3px] bg-transparent p-1.5 text-lg/6 font-semibold no-underline transition-all duration-[0.25s] hover:border-white hover:bg-white/10 hover:text-white',
				pathname === link ? 'border-white text-white' : 'border-mds-neutral-10 text-white/80'
			)}
			to={link}
		>
			{render}
		</Link>
	);
}

function MobileAppbar() {
	const dispatch = useAppDispatch();
	const { pathname } = useLocation();
	const sidebarExpanded = useAppSelector(state => state.main.sidebar.expanded);

	return (
		<div className="sticky top-0 z-[100] flex h-16 max-h-16 w-full items-center justify-between overflow-hidden bg-mds-neutral-10 p-1 text-white/80 shadow-lg">
			<IconButton
				color="inherit"
				className={pathname.includes('campaign_details') ? '' : 'opacity-0'}
				onClick={() => dispatch(updateSidebar({ expanded: !sidebarExpanded }))}
			>
				<MenuIcon />
			</IconButton>

			<Link className="bg-transparent" to="/">
				<img src="/maximus_logo.png" alt="maximus" className="mx-3 h-10" />
			</Link>

			<UserSettingsDropdown />
		</div>
	);
}

export default function Appbar({ test = false, isSocial = false }: { test?: boolean; isSocial?: boolean }) {
	const { isLoading } = useAuth0();
	const { pathname } = useLocation();
	const email = useAppSelector(state => state.main.email);
	const [hydrated, setHydrated] = useState(false);

	useEffect(() => {
		setHydrated(true);
	}, []);

	if (!hydrated) return <div></div>;

	if (isLoading && !test) return false;

	if (window.innerWidth <= 768) return <MobileAppbar />;

	return (
		<div className="sticky top-0 z-[100] inline-flex h-[70px] max-h-[70px] w-full items-center justify-between overflow-hidden bg-mds-neutral-10 p-1 text-white/80 shadow-md">
			<div className="relative flex items-center gap-4">
				<Link className="bg-transparent" to="/">
					<img src="/maximus_logo.png" alt="maximus" className="ml-3 mr-6 h-[51px] w-[205px]" />
				</Link>
				{!isSocial ? (
					<>
						<AppbarLink link="/" render="Manage Campaigns" />
						<AddCampaignDropdown />
						<AppbarLink link="/creatives_gallery" render="Creatives Gallery" />
						<AppbarLink link="/top_ads" render="Top Ads" />
					</>
				) : (
					<>
						<AppbarLink link="/ai_generation" render="AI Generation" />
						<AppbarLink link="/video_swap" render="Video Swap" />
					</>
				)}
			</div>

			<div className="relative mr-3 flex items-center justify-end gap-2">
				{email}
				{!isSocial && !pathname.includes('add_campaign_') && <RefreshButton />}
				<IssueReport color="inherit" />
				{!isSocial && <HelpPageIcon />}
				<UserSettingsDropdown />
			</div>
		</div>
	);
}
