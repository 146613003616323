import ReportTable from '@/Components/DataTable/ReportTable';
import { GridColDef } from '@mui/x-data-grid-premium';
import { ClipboardCell } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/ClipboardCell.tsx';

const columns = [
	{ field: 'user_name', headerName: 'User', width: 190 },
	{ field: 'date', headerName: 'Date', width: 200, type: 'timestamp' },
	{ field: 'event_name', headerName: 'User Action', width: 230 },
	{
		field: 'message',
		headerName: 'Message',
		flex: 1000,
		renderCell: params => <ClipboardCell content={params.value} />,
	},
] satisfies GridColDef[];

export default function CampaignLog() {
	return (
		<div className="campaign-details-report">
			<ReportTable columns={columns} tableName="Campaign Log" endpoint="api/v1/campaign/reporting/log" />
		</div>
	);
}
