import AdSetup from '@/Components/Pages/AddCampaign/Pages/Ads/AdSetup';
import { resetAdSetup } from '@/Redux/Slices/addCampaign';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import '../../../AddCampaign/addCampaign.css';

export default function AddAds() {
	const dispatch = useAppDispatch();
	const { network } = useAppSelector(state => state.campaignDetails);

	useEffect(() => {
		dispatch(resetAdSetup({ network }));
	}, [dispatch, network]);

	return <AdSetup campaignDetails />;
}
