import { FormControl, SxProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useMemo } from 'react';

interface InputProps {
	onChange: (value: Date | null) => void;
	label?: string;
	value: string;
	name: string;
	helperText?: string;
	disabled?: boolean;
	error?: boolean;
	disablePastDates?: boolean;
	size?: SxProps;
}

export default function DatePickerField({
	onChange,
	label = '',
	value,
	name,
	disabled = false,
	error = false,
	disablePastDates = false,
	helperText = '',
	size = { m: '12px', width: 'calc(33.33% - 24px)' },
}: InputProps) {
	const dateValue = useMemo(() => {
		const date = new Date(value);
		date.setUTCHours(12); // Set to Zulu noon to avoid negative midnight issue that shows the previous day
		return date;
	}, [value]);

	return (
		<FormControl sx={size} variant="filled">
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					name={name}
					label={label}
					value={dateValue}
					onChange={onChange}
					disabled={disabled}
					disablePast={disablePastDates}
					slotProps={{
						textField: {
							error,
							helperText,
							variant: 'filled',
							id: `date-picker-${name}`,
						},
					}}
				/>
			</LocalizationProvider>
		</FormControl>
	);
}
