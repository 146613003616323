import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export default function FieldContainer({ children, className }: { children: ReactNode; className?: string }) {
	return (
		<div
			className={twMerge(
				'flex w-full flex-col items-start justify-start rounded-md border border-black/80 bg-white p-3 shadow-md',
				className
			)}
		>
			{children}
		</div>
	);
}
