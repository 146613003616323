import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { GridAlignment, GridColDef, sanitizeFilterItemValue } from '@mui/x-data-grid-premium';

export function customBooleanColDef(
	trueLabel: string,
	falseLabel: string,
	options: { align: GridAlignment }
): Partial<GridColDef> {
	return {
		type: 'boolean',
		align: options.align,
		headerAlign: options.align,
		filterOperators: [
			{
				value: 'is',
				getApplyFilterFn: filterItem => {
					const sanitizedValue = sanitizeFilterItemValue(filterItem.value);
					if (sanitizedValue === undefined) return null;
					return value => Boolean(value) === sanitizedValue;
				},
				InputComponent: ({ item, applyValue }) => {
					const items = [
						{ value: '', label: 'Any' },
						{ value: 'true', label: trueLabel },
						{ value: 'false', label: falseLabel },
					];
					return (
						<FormControl variant="standard">
							<InputLabel shrink>Value</InputLabel>
							<Select
								displayEmpty
								value={item.value || ''}
								onChange={e => {
									applyValue({ ...item, value: e.target.value || '' });
								}}
							>
								{items.map(option => (
									<MenuItem key={option.value} value={option.value}>
										{option.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					);
				},
			},
		],
	};
}
