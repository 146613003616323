import { useCallback, useEffect, useMemo, useState } from 'react';
import lodash from 'lodash';
import { format } from 'date-fns';
import {
	DataGridPremiumProps,
	GridCellModesModel,
	GridColDef,
	GridColumnVisibilityModel,
	GridEventListener,
	GridPinnedColumnFields,
	useGridApiRef,
} from '@mui/x-data-grid-premium';
import { useCancellableNetworkRequest } from '@/utils/networkRequest.ts';
import { useAppDispatch, useAppSelector, usePrevious } from '@/hooks.ts';
import DataGrid from './DataGrid.tsx';
import { updateMainStore } from '@/Redux/Slices/main.ts';
import { refreshAll } from '@/Redux/Slices/filters.ts';
import {
	columnReorderHelper,
	columnVisibilityHelper,
	onColumnOrderChange,
	onColumnVisibilityChange,
} from './Helpers/ColumnReorderHelper';
import { estimatedFieldsMap } from './constants.ts';
import { isOutbrain, isRevContent } from '@/utils/network-utils.ts';

const { isEqual } = lodash;

const formatRows = (rows: Array<object>) => rows.map((row: object, id: number) => ({ ...row, id: id + 1 }));

const onlyEstimatedRCReports = ['OS Targeting', 'Platforms', 'States', 'By Hour'];
const onlyEstimatedOutbrainReports = ['OS Targeting', 'By Hour'];
const onlyEstimatedAllNetworksReports = ['Browsers'];

interface ReportTableProps
	extends Pick<
		DataGridPremiumProps,
		| 'getRowHeight'
		| 'rows'
		| 'treeData'
		| 'getTreeDataPath'
		| 'loading'
		| 'isGroupExpandedByDefault'
		| 'groupingColDef'
		| 'getRowClassName'
	> {
	columns: GridColDef[];
	defaultPinnedColumns?: GridPinnedColumnFields;
	tableName: string;
	tableKey?: string;
	uniqueTableId?: string;
	endpoint?: string;
	filters?: string[];
	notCampaignDetails?: boolean;
	notUseDefaultFilters?: boolean;
	exportAllColumns?: boolean;
	additionalRequestParams?: object;
	aggregationModel?: object;
	sortModel?: object[];
	rowHeight?: number;
	tableHeight?: string,
	cellModesModel?: object;
	columnVisibility?: GridColumnVisibilityModel;
	onCellModesModelChange?: (model: GridCellModesModel) => void;
	processRowUpdate?: (updatedRow: object, originalRow: object) => Promise<unknown>;
}

export default function ReportTable(props: ReportTableProps) {
	const {
		columns,
		defaultPinnedColumns,
		columnVisibility = {},
		tableName: propsTableName,
		tableKey = propsTableName,
		uniqueTableId,
		endpoint,
		rows: propsRows,
		rowHeight,
		tableHeight = '700px',
		filters = [],
		notCampaignDetails = false,
		notUseDefaultFilters = false,
		additionalRequestParams = {},
		aggregationModel = {},
		groupingColDef,
		loading: propsLoading,
		sortModel = [],
		treeData,
		cellModesModel = {},
		exportAllColumns = false,
		getRowClassName,
		getRowHeight,
		getTreeDataPath,
		isGroupExpandedByDefault,
		onCellModesModelChange,
		processRowUpdate,
	} = props;
	const filterStore = useAppSelector(state => state.filters);
	const storeRows = useAppSelector(state => state.main.tableRows);
	const { campaignID, network, estimatedSpends } = useAppSelector(state => state.campaignDetails);
	const [_loading, setLoading] = useState<boolean>(true);
	const dispatch = useAppDispatch();
	const apiRef = useGridApiRef();

	const tableName = uniqueTableId || propsTableName;

	if (!!endpoint && !!propsRows) {
		throw new Error('ReportTable: You should provide either `endpoint` or `rows`, not both');
	}

	const rows = useMemo(() => {
		return endpoint ? storeRows : propsRows;
	}, [endpoint, propsRows, storeRows]);

	const loading = useMemo(() => {
		return propsLoading !== undefined ? propsLoading : _loading;
	}, [_loading, propsLoading]);

	const disableColumnPinning = window.innerWidth < 840;

	const setFormattedFilters = (): object => {
		const formattedFilters: object = {};
		const defaultFilters = ['startDate', 'endDate', 'dateRange', 'weekDay'];
		let filtersList = [...filters];
		if (!notUseDefaultFilters) {
			filtersList = [...filtersList, ...defaultFilters];
		}
		Object.keys(filterStore).forEach(filter => {
			if (filtersList.includes(filter) && filterStore[filter] !== undefined) {
				if (filter === 'startDate' || filter === 'endDate') {
					formattedFilters[filter] = format(new Date(filterStore[filter]), 'yyyy-MM-dd');
				} else {
					formattedFilters[filter] = filterStore[filter];
				}
			}
		});
		if (!notCampaignDetails) {
			formattedFilters.campaignID = campaignID;
			formattedFilters.network = network;
		}
		return {
			...formattedFilters,
			...additionalRequestParams,
		};
	};

	const previousFilters = usePrevious(setFormattedFilters());
	const previousEndpoint = usePrevious(endpoint);

	const cancellableRequest = useCancellableNetworkRequest();

	const getData = async () => {
		if (!endpoint) return;
		const formattedFilters = setFormattedFilters();
		setLoading(true);
		dispatch(refreshAll(false));
		dispatch(updateMainStore({ key: 'tableRows', value: [] }));
		cancellableRequest(
			endpoint,
			{
				...formattedFilters,
			},
			'POST'
		)
			.then(response => response?.json())
			.then(response => {
				if (!(response && typeof response === 'object')) return;
				dispatch(updateMainStore({ key: 'tableRows', value: formatRows(response) }));
			})
			.catch(() => {
				dispatch(updateMainStore({ key: 'tableRows', value: [] }));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const modifyColumns = (columns: GridColDef[]): GridColDef[] => {
		const onlyEstimatedRCReportsCondition = isRevContent(network) && onlyEstimatedRCReports.includes(tableKey);
		const onlyEstimatedOutbrainReportsCondition =
			isOutbrain(network) && onlyEstimatedOutbrainReports.includes(tableKey);
		const onlyEstimatedAllNetworksReportsCondition = onlyEstimatedAllNetworksReports.includes(tableKey);
		if (
			!notCampaignDetails &&
			(onlyEstimatedRCReportsCondition ||
				onlyEstimatedOutbrainReportsCondition ||
				onlyEstimatedAllNetworksReportsCondition)
		) {
			const columnsNames = Object.keys(estimatedFieldsMap);
			return columns.map(column => ({
				...column,
				field: columnsNames.includes(column.field) ? estimatedFieldsMap[column.field] : column.field,
			}));
		}
		return columns;
	};

	useEffect(() => {
		const columnHeaderDragEndUnsub = apiRef.current.subscribeEvent('columnHeaderDragEnd', () => {
			onColumnOrderChange(modifyColumns(apiRef?.current?.getAllColumns() || []), tableName);
		});

		const columnVisibilityModelChangeUnsub = apiRef.current.subscribeEvent('columnVisibilityModelChange', () => {
			return onColumnVisibilityChange(
				modifyColumns(apiRef?.current?.getVisibleColumns() || []),
				tableName,
				modifyColumns(columns)
			);
		});

		const currentFilters = JSON.parse(localStorage.getItem(`${tableName}_filterModel`) || 'false');
		if (currentFilters) {
			try {
				apiRef.current.restoreState({ filter: { filterModel: currentFilters } });
			} catch (error) {
				console.warn('Error restoring saved filterModel, restoring to default blank value. Original trace:\n', error);
				localStorage.removeItem(`${tableName}_filterModel`);
				apiRef.current.restoreState({ filter: { filterModel: { items: [] }}});
			}
		}
		const filterModelChangeUnsub = apiRef.current.subscribeEvent('filterModelChange', (filters, details) => {
			if ((details as any).reason === 'removeAllFilterItems' || filters.items.length === 0) {
				localStorage.removeItem(`${tableName}_filterModel`);
			} else {
				localStorage.setItem(`${tableName}_filterModel`, JSON.stringify(filters));
			}
		});

		const currentPinnedCols = JSON.parse(localStorage.getItem(`${tableName}_pinnedCols`) || 'false');
		if (currentPinnedCols && !disableColumnPinning) {
			apiRef.current.restoreState({ pinnedColumns: currentPinnedCols });
		}
		const pinnedColsModelChangeUnsub = apiRef.current.subscribeEvent('pinnedColumnsChange', pinnedCols => {
			localStorage.setItem(`${tableName}_pinnedCols`, JSON.stringify(pinnedCols));
		});

		const currentDensity = JSON.parse(localStorage.getItem(`${tableName}_density`) || 'false');
		if (currentDensity) {
			apiRef.current.restoreState({ density: currentDensity });
		}
		const densityModelChangeUnsub = apiRef.current.subscribeEvent('densityChange', density => {
			if (density === 'standard') {
				localStorage.removeItem(`${tableName}_density`);
			} else {
				localStorage.setItem(`${tableName}_density`, JSON.stringify(density));
			}
		});

		return () => {
			columnHeaderDragEndUnsub();
			columnVisibilityModelChangeUnsub();
			filterModelChangeUnsub();
			pinnedColsModelChangeUnsub();
			densityModelChangeUnsub();
		};
	}, [apiRef, columns, tableName]);

	useEffect(() => {
		if (!notCampaignDetails && !campaignID) return;

		if (
			!isEqual(setFormattedFilters(), previousFilters) ||
			filterStore.refreshAllData ||
			previousEndpoint !== endpoint
		) {
			getData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setFormattedFilters, previousFilters, filterStore.refreshAllData, endpoint, previousEndpoint]);

	const handleCellEditStop = useCallback<GridEventListener<'cellEditStop'>>((params, event) => {
		event.defaultMuiPrevented = true;
	}, []);

	const dateRangeTitle = `${format(new Date(filterStore.startDate), 'yyyy-MM-dd')} - ${format(new Date(filterStore.endDate), 'yyyy-MM-dd')}`;
	const defaultSortModel =
		estimatedSpends && tableName !== 'States'
			? [
					{
						field: 'estimatedSpend',
						sort: 'desc',
					},
				]
			: [{ field: 'spend', sort: 'desc' }];

	return (
		<DataGrid
			apiRef={apiRef}
			rows={rows}
			columns={modifyColumns(columnReorderHelper(modifyColumns(columns), tableName, estimatedSpends))}
			height={tableHeight}
			tableName={propsTableName}
			dateRangeTitle={dateRangeTitle}
			rowHeight={rowHeight || null}
			getRowHeight={getRowHeight}
			loading={loading}
			aggregationModel={aggregationModel}
			isGroupExpandedByDefault={isGroupExpandedByDefault}
			groupingColDef={groupingColDef}
			defaultPinnedColumns={disableColumnPinning ? {} : defaultPinnedColumns}
			disableColumnPinning={disableColumnPinning}
			sortModel={!sortModel.length ? defaultSortModel : sortModel}
			treeData={treeData}
			cellModesModel={cellModesModel}
			getRowClassName={getRowClassName}
			getTreeDataPath={getTreeDataPath}
			onCellEditStop={handleCellEditStop}
			onCellModesModelChange={onCellModesModelChange}
			processRowUpdate={processRowUpdate}
			columnVisibilityModel={columnVisibilityHelper(modifyColumns(columns), tableName, estimatedSpends)}
			lockedVisibilityModel={columnVisibility}
			exportAllColumns={exportAllColumns}
		/>
	);
}
