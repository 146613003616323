import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '../hooks';
import { bulkUpdateMainStore, updateMainStore } from '../Redux/Slices/main';
import { getFromLocalStorage } from './local-storage-utils.ts';
import networkRequest from './networkRequest.ts';

function parseJwt(token: string) {
	const base64Url = token.split('.')[1];
	const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
			.join('')
	);

	return JSON.parse(jsonPayload);
}

const pathWhitelist = ['/help/'];

const encodeData = (data: object) => {
	return btoa(JSON.stringify(data));
};

const decodeData = (encodedData: string) => {
	return JSON.parse(atob(encodedData));
};

export default function useAuth() {
	const { isLoading, isAuthenticated, getAccessTokenSilently, user, logout } = useAuth0();
	const { token: storedToken, userID: storedUserID } = useAppSelector(state => state.main);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [authInterval] = useState(Math.random() * (600_000 - 300_000) + 300_000);
	const interval = useRef<ReturnType<typeof setTimeout>>();

	const doLogout = () => {
		localStorage.removeItem(getFromLocalStorage('userID') || '');
		localStorage.removeItem(`${getFromLocalStorage('userID')}_filters`);
		localStorage.removeItem('userID');
		localStorage.removeItem('userSettings_detailsReport');
		const endpoint = window.location.origin + '/login';
		logout({ logoutParams: { returnTo: endpoint } }).then();
	};

	const storeMetaData = (token: string) => {
		try {
			const parsedToken = parseJwt(token);
			if (parsedToken && parsedToken.is_admin_user) {
				const storedSocialView =
					localStorage.getItem('adminSocialView') && localStorage.getItem('adminSocialView') === 'true';
				dispatch(bulkUpdateMainStore({ isAdminUser: true, isSocialUser: storedSocialView }));
			} else if (parsedToken && parsedToken.is_social_user) {
				dispatch(updateMainStore({ key: 'isSocialUser', value: true }));
			}
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (isLoading) return;

		if (!isAuthenticated || !user) {
			dispatch(updateMainStore({ key: 'appLoaded', value: true }));
			if (pathname !== '/login') navigate('/login');
			return;
		}

		const checkAuth = async () => {
			const userData = localStorage.getItem('userData');
			if (userData) {
				try {
					const decoded = decodeData(userData);
					const { timestamp, authenticated } = decoded;
					const currentTime = new Date().getTime();
					const tokenAge = currentTime - timestamp;

					if (authenticated && tokenAge < 300_000) {
						return;
					}
				} catch (e) {
					console.error('Invalid token', e);
				}
			}

			networkRequest(
				'api/user/check',
				{
					externalID: user.sub,
					email: user.email,
					name: user.name,
				},
				'GET'
			)
				.then(response => response && response.json())
				.then(response => {
					if (response && typeof response === 'object' && !response.success) {
						doLogout();
					} else if (response && typeof response === 'object' && response.success) {
						const userData = encodeData({
							timestamp: new Date().getTime(),
							authenticated: true,
						});
						localStorage.setItem('userData', userData);
					}
				});
		};

		if (!pathWhitelist.some(entry => pathname.includes(entry))) {
			checkAuth().then();
			if (interval.current) clearInterval(interval.current);
			interval.current = setInterval(() => checkAuth(), authInterval);
		}

		const storeAuth = async () => {
			const { id_token: token } = await getAccessTokenSilently({ detailedResponse: true });

			storeMetaData(token);

			if (user.email) {
				dispatch(updateMainStore({ key: 'email', value: user.email }));
			}

			if (!storedToken) {
				dispatch(updateMainStore({ key: 'token', value: token }));
			}

			if (!storedUserID) {
				dispatch(updateMainStore({ key: 'userID', value: user.sub }));
				localStorage.setItem('userID', user.sub as string);
			}

			if (pathname === '/login') {
				navigate('/');
			}

			dispatch(updateMainStore({ key: 'appLoaded', value: true }));
		};

		storeAuth().then();

		return () => {
			clearInterval(interval.current);
		};
	}, [
		isLoading,
		isAuthenticated,
		getAccessTokenSilently,
		storedToken,
		storedUserID,
		user,
		dispatch,
		navigate,
		pathname,
	]);
}
