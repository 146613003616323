import { useAppDispatch, useAppSelector } from '@/hooks';
import { changeSettingsField, resetAddCampaign } from '@/Redux/Slices/addCampaign.ts';
import { changeCampaignDetails, resetCampaignDetails } from '@/Redux/Slices/campaignDetails';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { Network } from '@/utils/network-utils.ts';
import { useCancellableNetworkRequest } from '@/utils/networkRequest';
import { useEffect, useMemo, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export const useCampaignNotificationsFunc = () => {
	const dispatch = useAppDispatch();
	const cancellableRequest = useCancellableNetworkRequest();
	return (campaignID: string, network: number) =>
		cancellableRequest('api/v1/campaign/details/notifications', { campaignID, network }, 'POST')
			.then(response => {
				if (response?.ok) return response.json();
				throw new Error('Error');
			})
			.then(campaignNotifications => {
				dispatch(changeCampaignDetails({ key: 'campaignNotifications', value: campaignNotifications || [] }));
			});
};

export const useNoCampaignIDError = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	return () => {
		dispatch(
			openSnackbar({
				children: 'Something went wrong while pulling campaign data. Redirecting you to Manage Campaigns.',
				severity: 'error',
			})
		);
		dispatch(changeCampaignDetails({ key: 'campaignID', value: '' }));
		navigate('/');
	};
};

export const useGetCampaignIDFromName = () => {
	const { id: campaignName } = useParams<{ id: string }>();
	const setNoCampaignIDError = useNoCampaignIDError();
	const cancellableRequest = useCancellableNetworkRequest();
	return async () => {
		return cancellableRequest('api/v1/campaign/details/getIDFromName', { campaignName }, 'POST').then(response => {
			if (response?.status === 200) {
				return response.json();
			}
			setNoCampaignIDError();
			return false;
		});
	};
};

export const useCampaignDetails = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const cancellableRequest = useCancellableNetworkRequest();
	const { updatedCampaignSettings, campaignID, network, campaignInfoLoading } = useAppSelector(
		state => state.campaignDetails
	);
	const location = useLocation();
	const campaignIDFromLocation = location.state?.campaignID;
	const notificationsCall = useCampaignNotificationsFunc();
	const notificationsInterval = useRef<ReturnType<typeof setInterval>>();

	const url = window.location.href;
	const networkFromUrl = useMemo(() => {
		if (url.includes('T_')) return Network.Taboola;
		if (url.includes('O_')) return Network.Outbrain;
		if (url.includes('RC_')) return Network.RevContent;
		return Network.Taboola;
	}, [url]);

	useEffect(() => {
		if (campaignIDFromLocation) {
			getCampaignDetails(campaignIDFromLocation);
			dispatch(changeCampaignDetails({ key: 'campaignID', value: campaignIDFromLocation }));
		}
		return () => {
			clearInterval(notificationsInterval.current);
			dispatch(resetCampaignDetails());
			dispatch(resetAddCampaign({ network }));
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (updatedCampaignSettings && campaignID) {
			getCampaignDetails(campaignID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updatedCampaignSettings]);

	useEffect(() => {
		if (notificationsInterval.current) clearInterval(notificationsInterval.current);
		if (campaignID) {
			notificationsInterval.current = setInterval(() => notificationsCall(campaignID, networkFromUrl), 185_000);
			getCampaignDetails(campaignID);
		}
		// eslint-disable-next-line
	}, [campaignID]);

	const getCampaignDetails = (id: string) => {
		dispatch(changeCampaignDetails({ key: 'campaignInfoLoading', value: true }));
		cancellableRequest(
			'api/v1/campaign/details/pull',
			{
				campaignID: id,
				network: networkFromUrl,
			},
			'POST'
		)
			.then(response => {
				if (response?.status === 403) {
					dispatch(
						openSnackbar({
							children: 'Your account does not have access to this campaign. Redirecting you to Manage Campaigns.',
							severity: 'error',
						})
					);
					navigate('/');
					return Promise.reject('Access Denied');
				}
				return response?.json();
			})
			.then(response => {
				if (response && typeof response === 'object') {
					const {
						campaignName,
						network,
						campaignNotifications,
						maximusLinks,
						estimatedSpends,
						hasSiteBids,
						hasCpc,
						advancedTracking,
						linkTestNotifications,
					} = response;
					dispatch(changeCampaignDetails({ key: 'campaignName', value: campaignName || 'Test' }));
					dispatch(changeCampaignDetails({ key: 'network', value: network || Network.Taboola }));
					dispatch(changeCampaignDetails({ key: 'campaignID', value: id }));
					dispatch(changeCampaignDetails({ key: 'campaignNotifications', value: campaignNotifications || [] }));
					dispatch(changeCampaignDetails({ key: 'linkTestNotification', value: linkTestNotifications[0] || '' }));
					dispatch(changeCampaignDetails({ key: 'maximusLinks', value: maximusLinks || false }));
					dispatch(changeSettingsField({ name: 'maximusLinks', value: maximusLinks || false }));
					dispatch(changeCampaignDetails({ key: 'advancedTracking', value: advancedTracking || false }));
					dispatch(changeCampaignDetails({ key: 'estimatedSpends', value: estimatedSpends || false }));
					dispatch(changeCampaignDetails({ key: 'hasSiteBids', value: hasSiteBids || false }));
					dispatch(changeCampaignDetails({ key: 'hasCpc', value: hasCpc || false }));
					dispatch(changeCampaignDetails({ key: 'updatedCampaignSettings', value: false }));
					dispatch(changeCampaignDetails({ key: 'campaignArchiveStatus', value: response.archiveStatus || false }));
					dispatch(changeCampaignDetails({ key: 'previewLink', value: response.baseLink || '' }));
					dispatch(changeCampaignDetails({ key: 'isOtto', value: response.isOtto || false }));
					dispatch(changeCampaignDetails({ key: 'campaignInfoLoading', value: false }));
				}
			})
			.catch(e => {
				console.error(e);
			});
	};
};
