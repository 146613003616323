import networkRequest from '../../../../utils/networkRequest';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { Network } from '@/utils/network-utils.ts';

export async function updateEstimatedSpends(value: boolean, dispatch: any, campaignID: string, network: Network) {
	return networkRequest('api/v1/campaign/estimatedSpends/update', { campaignID, estimatedSpends: value, network })
		.then(response => response.json())
		.then(response => {
			if (!response.success) {
				dispatch(
					openSnackbar({
						children: response.message || 'Something went wrong. Please try again.',
						severity: 'error',
					})
				);
			} else {
				dispatch({ type: 'campaignDetails/toggleEstimatedSpends' });
			}
		});
}
