import { IconButton } from '@mui/material';
import { useAppDispatch } from '@/hooks.ts';
import Copy from '../../../../../../Icons/Copy';
import { openSnackbar } from '@/Redux/Slices/main.ts';

export function ClipboardCell({ content }: { content: string }) {
	const dispatch = useAppDispatch();

	const copyText = async () => {
		try {
			await navigator.clipboard.writeText(content);
			dispatch(openSnackbar({ children: 'Copied to clipboard!', severity: 'success' }));
		} catch (err) {
			dispatch(openSnackbar({ children: 'Text could not be copied', severity: 'error' }));
		}
	};

	return (
		<div className="flex items-center">
			<span className="truncate" style={{ marginRight: 8 }}>
				{content}
			</span>
			<IconButton size="small" onClick={copyText}>
				<Copy style={{ width: 20, height: 20 }} />
			</IconButton>
		</div>
	);
}
