import CreativesSearchForm from '@/Components/Pages/AddCreatives/SearchForm.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks';
import Add from '@/Icons/Add';
import Edit from '@/Icons/Edit';
import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import SectionTitle from '../../Components/SectionTitle.tsx';
import AddAdsSection from './AddAdsSection.tsx';
import CopyAdsButton from './CopyAdsButton.tsx';
import DisplayAdsSection from './DisplayAdsSection.tsx';
import { Ad, changeNonField } from '@/Redux/Slices/addCampaign.ts';
import Toggle from '@/Components/FormComponents/Toggle.tsx';
import { isOutbrain } from '@/utils/network-utils.ts';
import DialogComponent from '@/utils/UIElements/Dialog.tsx';

enum Tab {
	AddAds,
	ViewAds,
	SearchCreatives,
}

export default function AdSetup({ isCreatives = false, campaignDetails = false }) {
	const dispatch = useAppDispatch();
	const { ads, outbrainVideoAds } = useAppSelector(state => state.addCampaign);
	const { network } = useAppSelector(state => state.campaignDetails);
	const [tab, setTab] = useState(Tab.AddAds);
	const [dialogOpen, setDialogOpen] = useState(false);

	useEffect(() => {
		if (ads.length > 0) setTab(Tab.ViewAds);
	}, [ads]);

	const checkForWrongTypeAds = (currentVideoAds: boolean) => {
		const wrongType = (ad: Ad) => {
			const videoTypeAds = ad.fileType === 'video' || ad.image.includes('.gif');
			return currentVideoAds ? !videoTypeAds : videoTypeAds;
		};
		const wrongTypeAds = ads.filter(ad => wrongType(ad));
		return wrongTypeAds.length > 0;
	};

	const removeWrongTypeAds = () => {
		return new Promise<void>(resolve => {
			const rightType = (ad: Ad) => {
				const videoTypeAds = ad.fileType === 'video' || ad.image.includes('.gif');
				return outbrainVideoAds ? videoTypeAds : !videoTypeAds;
			};
			const rightTypeAds = ads.filter(ad => rightType(ad));
			dispatch(changeNonField({ key: 'ads', value: rightTypeAds }));
			resolve();
		});
	};

	return (
		<div className="w-full">
			<SectionTitle
				title={isCreatives ? 'Creatives Setup' : 'Ad Setup'}
				EndAddition={
					<div className="mb-2 flex items-center gap-6">
						{!campaignDetails && isOutbrain(network) && !isCreatives && (
							<Toggle
								label="Clip Campaign (Video/Gif)"
								trackColor="#4285F4"
								value={outbrainVideoAds}
								disabled={campaignDetails}
								onChange={checked => {
									dispatch(changeNonField({ key: 'outbrainVideoAds', value: checked }));
									if (checkForWrongTypeAds(checked)) {
										setDialogOpen(true);
									}
								}}
								name="outbrain-video-ads"
							/>
						)}
						{tab !== Tab.AddAds && (
							<Button variant="contained" type="button" onClick={() => setTab(Tab.AddAds)}>
								<Add className="mr-3 size-6 fill-white" /> Add More {isCreatives ? 'Creatives' : 'Ads'}
							</Button>
						)}

						{tab !== Tab.ViewAds && (
							<Button variant="contained" type="button" onClick={() => setTab(Tab.ViewAds)}>
								<Edit className="mr-3 size-5 fill-white" /> View {isCreatives ? 'Creatives' : 'Ads'}
							</Button>
						)}

						{!isCreatives && (
							<>
								{tab !== Tab.SearchCreatives && (
									<Button variant="contained" type="button" onClick={() => setTab(Tab.SearchCreatives)}>
										Choose Gallery Creatives
									</Button>
								)}

								<CopyAdsButton />
							</>
						)}
					</div>
				}
			/>
			{dialogOpen && (
				<DialogComponent
					dialogOpen
					title="Wrong Ad Type"
					useConfirm={() => removeWrongTypeAds().then(() => setDialogOpen(false))}
					useCancel={() => {
						dispatch(changeNonField({ key: 'outbrainVideoAds', value: !outbrainVideoAds }));
						setDialogOpen(false);
					}}
				>
					<p>
						{outbrainVideoAds
							? 'Static images cannot be added to Clip type campaigns'
							: 'Gifs and videos can only be added to Clip type campaigns'}
						. Are you sure you would like to remove your current ads?
					</p>
				</DialogComponent>
			)}

			<main className="p-2">
				{tab === Tab.AddAds && <AddAdsSection isCreatives={isCreatives} />}
				{tab === Tab.ViewAds && <DisplayAdsSection isCreatives={isCreatives} />}
				{tab === Tab.SearchCreatives && <CreativesSearchForm />}
			</main>
		</div>
	);
}
