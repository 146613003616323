import { parseCreativesPayload } from '@/Components/Pages/AddCreatives/helpers.ts';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeAdSetup, changeNonField, resetAdSetup } from '@/Redux/Slices/addCampaign.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import networkRequest from '@/utils/networkRequest.ts';
import { modifyAdsForParams } from '../../../AddCampaign/Components/SubmitCampaign.tsx';
import { validateAds } from '../../../AddCampaign/Components/Validation/Ads.tsx';

export const useSubmitAds = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network, isOtto } = useAppSelector(state => state.campaignDetails);
	const addCampaign = useAppSelector(state => state.addCampaign);

	return async () => {
		if (addCampaign.adSetup.filesToUpload.length > 0) {
			dispatch(changeAdSetup({ generationTriggerFlag: true }));
			dispatch(
				openSnackbar({
					children: `Ads need to be generated before submitting. We've automatically started generating them for you. Please try submitting again once they're ready.`,
					severity: 'warning',
					customDuration: 8_000,
				})
			);
			return;
		}

		const newErrors = validateAds(addCampaign, network);

		if (Object.keys(newErrors.fields).length) {
			dispatch(changeNonField({ key: 'errors', value: newErrors }));
			const children = (
				<ul>
					{Object.entries(newErrors.fields).map(([key, value]) => (
						<li key={`error-${key}`}>{value}</li>
					))}
				</ul>
			);
			dispatch(openSnackbar({ children, severity: 'error' }));
			document.getElementById('generate-ads')?.scrollIntoView();
			return;
		}

		const creatives = parseCreativesPayload(addCampaign.ads?.filter(ad => !!ad.addToCreatives));
		if (creatives?.length) {
			await networkRequest('api/v1/campaign/creatives/add', { creatives })
				.then(response => response?.json())
				.then(data => {
					if (!data?.success) throw new Error('Submit error');
					dispatch(openSnackbar({ children: 'New creatives were saved successfully!', severity: 'success' }));
				})
				.catch(() => {
					dispatch(openSnackbar({ children: 'There was an error saving the creatives.', severity: 'error' }));
				});
		}

		await networkRequest(
			'api/v1/campaign/ad/create',
			{
				campaignID,
				network,
				ads: modifyAdsForParams(addCampaign.ads, isOtto),
			},
			'POST'
		)
			.then(response => response.json())
			.then(response => {
				if (response && typeof response === 'object' && response.success === true) {
					dispatch({ type: 'campaignDetails/closeDialog' });
					dispatch(resetAdSetup({ network }));
				} else {
					dispatch(
						openSnackbar({
							children: response.message || 'Something went wrong. Please try again',
							severity: 'error',
						})
					);
				}
			})
			.catch(() => {
				dispatch(
					openSnackbar({
						children: 'Something went wrong. Please try again',
						severity: 'error',
					})
				);
			});
	};
};
