import { useAppDispatch, useAppSelector } from '@/hooks';
import SpeechBubble from '@/Icons/SpeechBubble';
import { Ad, changeNonField } from '@/Redux/Slices/addCampaign';
import { openSnackbar } from '@/Redux/Slices/main';
import networkRequest from '@/utils/networkRequest';
import { Button, Chip, CircularProgress, Dialog, TextField, Tooltip } from '@mui/material';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

export default function AiRating({ ad }: { ad: Ad }) {
	const dispatch = useAppDispatch();
	const ads = useAppSelector(state => state.addCampaign.ads);
	const network = useAppSelector(state => state.campaignDetails.network);
	const storedRating = ad.rating || 0;

	const [modalOpen, setModalOpen] = useState(false);
	const [rating, setRating] = useState(storedRating);
	const [notes, setNotes] = useState('');
	const [submitting, setSubmitting] = useState(false);
	const [step, setStep] = useState(0);

	async function onRatingSubmit() {
		setSubmitting(true);
		const assets = [{ url: ad.dicerFile, numericalRating: rating, assetNote: notes, network }];
		return networkRequest('api/v1/campaign/reportAd/dicer', { assets })
			.then(res => res.json())
			.then(res => {
				if (!res?.success) throw new Error('No success response');
				dispatch(openSnackbar({ severity: 'success', children: 'Rating submitted successfully!' }));
			})
			.catch(e => {
				console.error(e);
				dispatch(openSnackbar({ severity: 'error', children: 'Failed to submit rating' }));
			})
			.finally(() => {
				setSubmitting(false);
			});
	}

	function parseStoredAds(after: { removeSelf?: boolean; removeSiblings?: boolean } = {}) {
		const parsedAds = ads
			.map(item => (item.dicerFile === ad.dicerFile ? { ...item, rating } : item))
			.filter(item => {
				if (after.removeSelf) return item.uuid !== ad.uuid;
				if (after.removeSiblings) return item.dicerFile !== ad.dicerFile;
				return true;
			});
		dispatch(changeNonField({ key: 'ads', value: parsedAds }));
		setModalOpen(false);
		setRating(storedRating);
		setNotes('');
	}

	return (
		<>
			<Tooltip
				title={storedRating > 0 ? 'This media was already rated' : 'Click to rate this AI generation and send feedback'}
			>
				<div className="mx-auto mt-3">
					<Chip
						icon={<SpeechBubble className="size-5 pl-1" />}
						className="w-fit gap-1 px-1"
						color="info"
						size="small"
						disabled={storedRating > 0}
						label="Rate AI Media"
						onClick={() => {
							setStep(0);
							setRating(storedRating);
							setNotes('');
							setModalOpen(true);
						}}
					/>
				</div>
			</Tooltip>

			<Dialog open={modalOpen} maxWidth="sm">
				<header className="border-b p-3 text-center text-3xl font-semibold">Rate AI Media</header>

				<section className="flex flex-col gap-5 px-10 py-6">
					{step === 0 ? (
						<>
							<p>
								Please rate this AI generation. Your feedback helps us improve our models and provide better results.
							</p>

							<img src={ad.dicerFile} className="rounded" />

							<div className="mx-auto">
								<div className="flex gap-1">
									{[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => (
										<button
											key={n}
											className={twMerge(
												'flex size-8 items-center justify-center rounded-md border border-black/15 bg-white p-0 transition hover:border-black/25',
												n < rating && 'bg-blue-100',
												rating === n && 'border-blue-500 bg-blue-500 text-white'
											)}
											onClick={() => setRating(n)}
										>
											{n}
										</button>
									))}
								</div>
								<div className="flex w-full select-none justify-between text-sm text-black/50">
									<span>Worst</span>
									<span>Neutral</span>
									<span>Best</span>
								</div>
							</div>

							{rating > 0 && (
								<TextField
									multiline
									placeholder="Add some notes to the report (optional)"
									value={notes}
									onChange={e => setNotes(e.target.value)}
								/>
							)}
						</>
					) : (
						<p>You submitted a low rating. Please let us know if you want to remove any ads with this same media</p>
					)}
				</section>

				<footer className="flex items-center justify-end gap-4 p-6">
					{step === 0 ? (
						<>
							<Button disabled={submitting} onClick={() => setModalOpen(false)}>
								Close
							</Button>

							<Button
								variant="contained"
								disabled={!rating || submitting}
								onClick={async () => {
									await onRatingSubmit();
									if (rating <= 3) setStep(1);
									else parseStoredAds();
								}}
							>
								{submitting && <CircularProgress size={20} color="inherit" sx={{ mr: 2 }} />}
								Submit rating
							</Button>
						</>
					) : (
						<>
							<Button disabled={submitting} onClick={() => parseStoredAds()}>
								Close
							</Button>

							<Button
								variant="contained"
								disabled={!rating || submitting}
								onClick={() => parseStoredAds({ removeSelf: true })}
							>
								Remove this ad
							</Button>

							<Button
								variant="contained"
								disabled={!rating || submitting}
								onClick={() => parseStoredAds({ removeSiblings: true })}
							>
								Remove all ads with this media
							</Button>
						</>
					)}
				</footer>
			</Dialog>
		</>
	);
}
