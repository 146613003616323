import { useMemo } from 'react';
import capitalize from 'lodash/capitalize';
import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { useAppSelector } from '@/hooks.ts';
import { isTaboola } from '@/utils/network-utils.ts';
import { EstimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';

const columns = ([
	{
		field: 'device', headerName: 'Platform', width: 150,
		valueFormatter: (value: string) => capitalize(value),
	},
	{
		field: 'os', headerName: 'OS', width: 150,
	},
	...commonColumns(
		[
			'visits',
			'impressions',
			'clicks',
			'conversions',
			'average_cpc',
			'epc',
			'ctr',
			'cvr',
			'cpa',
			'roas',
			'revenue',
			'spend',
			'profit',
			'profit_percent',
		]),
]);



export default function OSTargeting() {
	const { estimatedSpends, network, advancedTracking, maximusLinks } = useAppSelector((state) => state.campaignDetails);

	const asyncColumns = useMemo(() => {
		return {
			visits: !isTaboola(network) && advancedTracking && maximusLinks,
			impressions: isTaboola(network),
		}
	}, [advancedTracking, maximusLinks, network]);

	return (
		<div className="campaign-details-report">
			{(!estimatedSpends && !isTaboola(network)) && <EstimatedReportsDisclaimer />}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns}
				tableName="OS Targeting"
				endpoint="api/v1/campaign/reporting/os"
				aggregationModel={{
					impressions: 'sum',
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					epc: 'avg',
					maximusEpc: 'avg',
					average_cpc: 'avg',
					ctr: 'avg',
					maximusCtr: 'avg',
					cpa: 'avg',
					maximusCpa: 'avg',
					profit: 'sum',
					profit_percent: 'profitPercent',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					cvr: 'avg',
					maximusCvr: 'avg',
					roas: 'avg',
					maximusRoas: 'avg',
					spend: 'sum',
					revenue: 'sum',
					os: 'blankColumn',
					device: 'blankColumn',
				}}
			/>
		</div>
	);
}
