import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { AddCampaignState, changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import { Network } from '@/utils/network-utils.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import SectionTitle from '../../Components/SectionTitle.tsx';
import ChipSelect from '../../Fields/ChipSelect.tsx';
import Select from '../../Fields/Select.tsx';
import {
	brandingTextField,
	budgetField,
	campaignNameField,
	conversionCapFields,
	countryTargetingField,
	cpcField,
	ottoSection,
	sectionTitleNetworkDetails,
	splitByDeviceToggle,
	startDateField,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/fields.tsx';
import {
	handleChipSelect,
	pullGroupOptions,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';
import {
	defaultAccountOptions,
	defaultGroupOptions,
	platformOptions,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';

const osTargetingOptions = [
	{ value: '0', label: 'All' },
	{ value: '4', label: 'Android' },
	{ value: '5', label: 'iOS' },
	{ value: '6', label: 'Windows Mobile' },
	{ value: '7', label: 'macOS' },
	{ value: '8', label: 'Windows Desktop' },
];

const browserOptions = [
	{ value: '0', label: 'All' },
	{ value: '1', label: 'Chrome' },
	{ value: '2', label: 'Firefox' },
	{ value: '3', label: 'IE' },
	{ value: '4', label: 'Safari' },
	{ value: '5', label: 'Edge' },
];

const languageOptions = [
	{ value: '1', label: 'English' },
	{ value: '45', label: 'Abkhazian' },
	{ value: '44', label: 'Afar' },
	{ value: '46', label: 'Afrikaans' },
	{ value: '24', label: 'Albanian' },
	{ value: '47', label: 'Amharic' },
	{ value: '7', label: 'Arabic' },
	{ value: '25', label: 'Armenian' },
	{ value: '48', label: 'Assamese' },
	{ value: '49', label: 'Aymara' },
	{ value: '50', label: 'Azerbaijani' },
	{ value: '51', label: 'Bashkir' },
	{ value: '63', label: 'Basque' },
	{ value: '10', label: 'Bengali' },
	{ value: '60', label: 'Bhutani' },
	{ value: '53', label: 'Bihari' },
	{ value: '54', label: 'Bislama' },
	{ value: '56', label: 'Breton' },
	{ value: '20', label: 'Bulgarian' },
	{ value: '101', label: 'Burmese' },
	{ value: '52', label: 'Byelorussian' },
	{ value: '83', label: 'Cambodian' },
	{ value: '57', label: 'Catalan' },
	{ value: '3', label: 'Chinese' },
	{ value: '58', label: 'Corsican' },
	{ value: '41', label: 'Croatian' },
	{ value: '36', label: 'Czech' },
	{ value: '15', label: 'Danish' },
	{ value: '12', label: 'Dutch' },
	{ value: '61', label: 'Esperanto' },
	{ value: '62', label: 'Estonian' },
	{ value: '66', label: 'Faeroese' },
	{ value: '64', label: 'Farsi' },
	{ value: '65', label: 'Fiji' },
	{ value: '34', label: 'Finnish' },
	{ value: '5', label: 'French' },
	{ value: '67', label: 'Frisian' },
	{ value: '70', label: 'Galician' },
	{ value: '80', label: 'Georgian' },
	{ value: '4', label: 'German' },
	{ value: '32', label: 'Greek' },
	{ value: '82', label: 'Greenlandic' },
	{ value: '71', label: 'Guarani' },
	{ value: '72', label: 'Gujarati' },
	{ value: '74', label: 'Hausa' },
	{ value: '21', label: 'Hebrew' },
	{ value: '6', label: 'Hindi' },
	{ value: '40', label: 'Hungarian' },
	{ value: '38', label: 'Icelandic' },
	{ value: '18', label: 'Indonesian' },
	{ value: '75', label: 'Interlingua' },
	{ value: '76', label: 'Interlingue' },
	{ value: '78', label: 'Inuktitut' },
	{ value: '77', label: 'Inupiak' },
	{ value: '68', label: 'Irish' },
	{ value: '13', label: 'Italian' },
	{ value: '11', label: 'Japanese' },
	{ value: '79', label: 'Javanese' },
	{ value: '84', label: 'Kannada' },
	{ value: '85', label: 'Kashmiri' },
	{ value: '81', label: 'Kazakh' },
	{ value: '111', label: 'Kinyarwanda' },
	{ value: '87', label: 'Kirghiz' },
	{ value: '110', label: 'Kirundi' },
	{ value: '31', label: 'Korean' },
	{ value: '86', label: 'Kurdish' },
	{ value: '91', label: 'Laothian' },
	{ value: '88', label: 'Latin' },
	{ value: '93', label: 'Latvian' },
	{ value: '89', label: 'Limburgish' },
	{ value: '90', label: 'Lingala' },
	{ value: '92', label: 'Lithuanian' },
	{ value: '43', label: 'Macedonian' },
	{ value: '94', label: 'Malagasy' },
	{ value: '16', label: 'Malay' },
	{ value: '96', label: 'Malayalam' },
	{ value: '100', label: 'Maltese' },
	{ value: '73', label: 'Manx' },
	{ value: '95', label: 'Maori' },
	{ value: '99', label: 'Marathi' },
	{ value: '98', label: 'Moldavian' },
	{ value: '97', label: 'Mongolian' },
	{ value: '102', label: 'Nauru' },
	{ value: '27', label: 'Nepali' },
	{ value: '33', label: 'Norwegian' },
	{ value: '103', label: 'Occitan' },
	{ value: '105', label: 'Oriya' },
	{ value: '104', label: 'Oromo' },
	{ value: '107', label: 'Pashto' },
	{ value: '28', label: 'Polish' },
	{ value: '8', label: 'Portuguese' },
	{ value: '106', label: 'Punjabi' },
	{ value: '108', label: 'Quechua' },
	{ value: '109', label: 'Rhaeto-romance' },
	{ value: '17', label: 'Romanian' },
	{ value: '9', label: 'Russian' },
	{ value: '118', label: 'Samoan' },
	{ value: '114', label: 'Sangro' },
	{ value: '112', label: 'Sanskrit' },
	{ value: '69', label: 'Scottish' },
	{ value: '121', label: 'Serbian' },
	{ value: '115', label: 'Serbo-croatian' },
	{ value: '123', label: 'Sesotho' },
	{ value: '129', label: 'Setswana' },
	{ value: '119', label: 'Shona' },
	{ value: '113', label: 'Sindhi' },
	{ value: '116', label: 'Sinhalese' },
	{ value: '122', label: 'Siswati' },
	{ value: '19', label: 'Slovak' },
	{ value: '117', label: 'Slovenian' },
	{ value: '120', label: 'Somali' },
	{ value: '2', label: 'Spanish' },
	{ value: '124', label: 'Sundanese' },
	{ value: '125', label: 'Swahili' },
	{ value: '14', label: 'Swedish' },
	{ value: '30', label: 'Swiss' },
	{ value: '22', label: 'Tagalog/Filipino' },
	{ value: '126', label: 'Tajik' },
	{ value: '23', label: 'Tamil' },
	{ value: '132', label: 'Tatar' },
	{ value: '42', label: 'Telugu' },
	{ value: '29', label: 'Thai' },
	{ value: '55', label: 'Tibetan' },
	{ value: '127', label: 'Tigrinya' },
	{ value: '130', label: 'Tonga' },
	{ value: '131', label: 'Tsonga' },
	{ value: '26', label: 'Turkish' },
	{ value: '128', label: 'Turkmen' },
	{ value: '133', label: 'Twi' },
	{ value: '134', label: 'Uighur' },
	{ value: '35', label: 'Ukrainian' },
	{ value: '39', label: 'Urdu' },
	{ value: '135', label: 'Uzbek' },
	{ value: '37', label: 'Vietnamese' },
	{ value: '136', label: 'Volap\u00fck' },
	{ value: '59', label: 'Welsh' },
	{ value: '137', label: 'Wolof' },
	{ value: '138', label: 'Xhosa' },
	{ value: '139', label: 'Yiddish' },
	{ value: '140', label: 'Yoruba' },
	{ value: '141', label: 'Zulu' },
];

const modifyOSTargetingOptions = (selectedPlatforms: string[]) => {
	const osOptionsByPlatform = {
		'0': ['0', '4', '5', '6', '7', '8'],
		'1': ['7', '8'],
		'2': ['4', '5', '6'],
		'3': ['4', '5', '6'],
	};
	const availableOptions = selectedPlatforms
		.flatMap(platform => osOptionsByPlatform[platform])
		.map(val => osTargetingOptions.find(opt => opt.value === val)!);
	return [...new Set(availableOptions)];
};

export const modifyOSTargetingValue = (newPlatformValue: string[]) => {
	const availableOsOptionKeys = modifyOSTargetingOptions(newPlatformValue).map(val => val.value);
	if (newPlatformValue.includes('0')) return ['0'];
	return availableOsOptionKeys;
};

export default function RevContentDetails({ campaignDetails = false }: { campaignDetails?: boolean }) {
	const [groupOptionsLoading, setGroupOptionsLoading] = useState(false);
	const [groupOptions, setGroupOptions] = useState(defaultGroupOptions);
	const [accountOptionsLoading, setAccountOptionsLoading] = useState(false);
	const [accountOptions, setAccountOptions] = useState(defaultAccountOptions);

	const dispatch = useAppDispatch();
	const { settings, errors, isClone } = useAppSelector(state => state.addCampaign);
	const updateSettings = <K extends keyof AddCampaignState['settings']>(
		name: K,
		value: AddCampaignState['settings'][K]
	) => {
		dispatch(changeSettingsField({ name, value }));
	};

	useEffect(() => {
		if (!campaignDetails) {
			pullGroupOptions(setGroupOptionsLoading, setGroupOptions);
		}
		if (isClone) {
			pullAccountOptions(settings.group);
		}
	}, []);

	const pullAccountOptions = (group: string) => {
		setAccountOptions(defaultAccountOptions);
		if (!group || group === '0') return;

		setAccountOptionsLoading(true);
		networkRequest('api/v1/accounts/pull', { group, network: Network.RevContent })
			.then(response => response.json())
			.then(data => {
				if (data?.length > 0) {
					setAccountOptions([
						...defaultAccountOptions,
						...data.map(account => ({ value: account.usm_id, label: account.name })),
					]);
				}
			})
			.finally(() => setAccountOptionsLoading(false));
	};

	const elementProps = { settings, errors, updateSettings };

	return (
		<>
			{sectionTitleNetworkDetails('RevContent Campaign Details', settings, updateSettings)}
			<div className="add-campaign-field-row">
				{campaignNameField(elementProps)}
				{!campaignDetails && startDateField(elementProps, campaignDetails, dispatch)}
				{budgetField(elementProps)}
			</div>
			<div className="add-campaign-field-row">
				{conversionCapFields(elementProps)}
				{cpcField(elementProps)}
			</div>
			<div className="add-campaign-field-row">
				<Select
					onChange={({ target: { value } }) => updateSettings('language', value)}
					label="Language"
					value={settings.language}
					name="language"
					options={languageOptions}
					error={!!errors.fields.language}
					helperText={errors.fields.language}
				/>
				{brandingTextField(elementProps)}
			</div>
			<div className="spacer-12" />
			{!campaignDetails && (
				<>
					<SectionTitle title="Account Details" />
					<div className="add-campaign-field-row">
						<Select
							onChange={({ target: { value } }) => {
								updateSettings('group', value);
								updateSettings('usmID', '0');
								pullAccountOptions(value);
							}}
							label="Group"
							value={settings.group}
							name="campaign-group"
							options={groupOptions}
							disabled={groupOptionsLoading}
							error={!!errors.fields.group}
							helperText={errors.fields.group}
						/>
						{settings.group !== '0' && accountOptions.length > 1 ? (
							<Select
								onChange={({ target: { value } }) => {
									updateSettings('usmID', value);
								}}
								label="Account"
								value={settings.usmID}
								name="usm-id"
								options={accountOptions}
								error={!!errors.fields.usmID}
								helperText={errors.fields.usmID}
							/>
						) : (
							accountOptionsLoading && (
								<div className="progress-inline-container">
									<CircularProgress />
								</div>
							)
						)}
					</div>
					<div className="spacer-12" />
				</>
			)}
			<SectionTitle title="Campaign Targeting" />
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) => {
						const newValue = handleChipSelect(value, settings.platformTargeting);
						updateSettings('platformTargeting', newValue);
						updateSettings('osTargeting', modifyOSTargetingValue(newValue));
					}}
					label="Platform Targeting"
					value={settings.platformTargeting}
					name="platform-targeting"
					options={platformOptions}
					error={!!errors.fields.platformTargeting}
					helperText={errors.fields.platformTargeting}
				/>
				<ChipSelect
					onChange={({ target: { value } }) =>
						updateSettings('osTargeting', handleChipSelect(value, settings.osTargeting))
					}
					label="OS Targeting"
					value={settings.osTargeting}
					name="os-targeting"
					options={modifyOSTargetingOptions(settings.platformTargeting)}
					error={!!errors.fields.osTargeting}
					helperText={errors.fields.osTargeting}
				/>
				{countryTargetingField(elementProps)}
			</div>
			{!campaignDetails && settings.platformTargeting.includes('0') && splitByDeviceToggle(elementProps)}
			<div className="add-campaign-field-row">
				<ChipSelect
					onChange={({ target: { value } }) =>
						updateSettings('browserTargeting', handleChipSelect(value, settings.browserTargeting))
					}
					label="Browser Targeting"
					value={settings.browserTargeting}
					name="browser-targeting"
					options={browserOptions}
					error={!!errors.fields.browserTargeting}
					helperText={errors.fields.browserTargeting}
				/>
			</div>
			<div className="spacer-12" />
			{ottoSection(elementProps)}
		</>
	);
}
