import { format, isBefore, isSameDay, parseISO } from 'date-fns';
import { Dispatch, SetStateAction } from 'react';
import networkRequest from '@/utils/networkRequest.ts';
import { defaultGroupOptions } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';

export const formatStringDate = (date: string, stringFormat = 'yyyy-MM-dd') => {
	try {
		if (!date) return '';
		const splitDate = date.split('-');
		if (splitDate.length !== 3) return '';
		if (splitDate[0].length !== 4) return date;
		return format(new Date(`${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`), stringFormat);
	} catch (e) {
		return '';
	}
};

export const checkPastDateDisable = (currentDate: string) => {
	if (!currentDate) return false;
	const currentDateFormatted = parseISO(formatStringDate(currentDate));
	const todayDate = new Date();
	todayDate.setHours(0, 0, 0, 0);
	currentDateFormatted.setHours(0, 0, 0, 0);
	return isBefore(currentDateFormatted, todayDate) || isSameDay(currentDateFormatted, todayDate);
};

export const checkPastDateEdit = (newDate: string) => {
	if (!newDate) return false;
	const newDateFormatted = parseISO(formatStringDate(newDate));
	const todayDate = new Date();
	todayDate.setHours(0, 0, 0, 0);
	return isBefore(newDateFormatted, todayDate);
};

export const handleChipSelect = (value: string[], allValues: string[]) => {
	const newValue: string[] = [];
	if (allValues.includes('0') && value.includes('0') && value.length > 1) {
		newValue.push(...value.filter(v => v !== '0'));
	} else if (value.includes('0') || value.length === 0) {
		newValue.push('0');
	} else {
		newValue.push(...value);
	}
	return newValue;
};

export const pullGroupOptions = (
	setGroupOptionsLoading: Dispatch<SetStateAction<boolean>>,
	setGroupOptions: Dispatch<
		SetStateAction<
			{
				value: string;
				label: string;
			}[]
		>
	>
) => {
	setGroupOptionsLoading(true);
	networkRequest('api/v1/user/group/pull', {}, 'POST')
		.then(response => response.json())
		.then(data => {
			if (data && typeof data === 'object' && data.length > 0) {
				setGroupOptions([...defaultGroupOptions, ...data.map(group => ({ value: group.id, label: group.name }))]);
			}
		})
		.finally(() => setGroupOptionsLoading(false));
};
