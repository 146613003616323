import type { ReactElement, ReactNode } from 'react';
import { useState } from 'react';
import { Button, DialogTitle, DialogContent, DialogActions, Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { changeCampaignDetails, closeDialog } from '../../../../Redux/Slices/campaignDetails';
import FormLoader from '../../../Loaders/FormLoader.tsx';
import DialogComponent from '../../../../utils/UIElements/Dialog.tsx';
import './settings.css';
import IssueReport from '@/utils/reporting/IssueReport';

export interface SettingsDialogRawProps {
	children: ReactElement;
	dialogTitle: ReactNode;
	plainTextTitle: string;
	handleConfirm: () => () => void;
	cancelButtonText?: string;
	confirmButtonText?: string;
	preConfirmDialog?: () => string;
	smallSize: boolean;
	handlePostConfirm?: () => () => void;
}

export default function SettingsDialog({
	children,
	handleConfirm,
	dialogTitle = '',
	plainTextTitle = '',
	cancelButtonText = 'Cancel',
	confirmButtonText = 'Save',
	preConfirmDialog,
	handlePostConfirm,
	smallSize,
}: SettingsDialogRawProps) {
	const dispatch = useAppDispatch();
	const { settingsDialogOpen, settingsDialogLoading, network } = useAppSelector(state => state.campaignDetails);
	const { settings } = useAppSelector(state => state.addCampaign);
	const [submitting, setSubmitting] = useState<boolean>(false);
	const [confirmDialogMessage, setConfirmDialogMessage] = useState<string>('');
	const [warningBudgetMessages, setWarningBudgetMessages] = useState<string[]>([]);
	const [confirmBudgetMessages, setConfirmBudgetMessages] = useState<string[]>([]);

	const handleClose = () => {
		dispatch(closeDialog());
	};

	const submit = (confirmed = false) => {
		setSubmitting(true);
		return handleConfirm(confirmed).then(warnings => {
			if (warnings?.warnings.length || warnings?.confirms.length) {
				setWarningBudgetMessages(warnings.warnings);
				setConfirmBudgetMessages(warnings.confirms);
			}
			setSubmitting(false);
		});
	};

	const closeWarningDialog = () => {
		setWarningBudgetMessages([]);
		setConfirmBudgetMessages([]);
		dispatch({ type: 'campaignDetails/closeDialog' });
		dispatch(changeCampaignDetails({ key: 'updatedCampaignSettings', value: true }));
	};

	return (
		<>
			<Dialog
				sx={{ '& .MuiDialog-paper': { width: '100%', maxHeight: '90vh' } }}
				maxWidth={smallSize ? 'sm' : 'lg'}
				open={settingsDialogOpen}
				disableEnforceFocus
			>
				<DialogTitle className="dialogTitle">{dialogTitle}</DialogTitle>
				<DialogContent dividers>{children}</DialogContent>
				<DialogActions className="settingsDialogActions">
					<Button autoFocus onClick={handleClose}>
						{cancelButtonText}
					</Button>
					<Button
						className="saveButton"
						disabled={submitting || settingsDialogLoading}
						onClick={() => {
							if (preConfirmDialog && preConfirmDialog({ ...settings, network })) {
								setConfirmDialogMessage(preConfirmDialog({ ...settings, network }));
							} else {
								submit();
							}
						}}
					>
						{submitting ? <FormLoader button /> : confirmButtonText}
					</Button>
				</DialogActions>
				<IssueReport
					label={plainTextTitle || (dialogTitle as string)}
					color="primary"
					className="absolute right-4 top-3"
					iconClassName="size-5"
				/>
			</Dialog>

			<DialogComponent
				title={confirmBudgetMessages.length ? 'Confirm' : 'Warning'}
				dialogOpen={!!warningBudgetMessages.length || !!confirmBudgetMessages.length}
				useCancel={closeWarningDialog}
				useConfirm={
					confirmBudgetMessages.length
						? async () => {
								if (handlePostConfirm) {
									await handlePostConfirm();
								}
								closeWarningDialog();
							}
						: null
				}
			>
				<>
					{warningBudgetMessages.map((msg, i) => <p key={i}>{msg}</p>)}
					{confirmBudgetMessages.map((msg, i) => <p key={i}>{msg}</p>)}
				</>
			</DialogComponent>

			<DialogComponent
				dialogOpen={!!confirmDialogMessage}
				title="Confirm"
				useConfirm={() => {
					setConfirmDialogMessage('');
					return submit(true);
				}}
				useCancel={() => setConfirmDialogMessage('')}
			>
				<p>{confirmDialogMessage}</p>
			</DialogComponent>
		</>
	);
}
