import { useState } from 'react';
import { useNavigate } from 'react-router';
import SectionTitle from './Components/SectionTitle';
import Settings from '../../../../Icons/Settings';
import SectionButton from './Components/SectionButton';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import DialogComponent from '../../../../utils/UIElements/Dialog.tsx';
import { deactivateLinkTest } from '../Settings/StartLinkTest/useSubmitStartLinkTest.tsx';
import { Network } from '@/utils/network-utils.ts';

const networkMap = new Map([
	[Network.Taboola, 'taboola'],
	[Network.RevContent, 'revcontent'],
	[Network.Outbrain, 'outbrain'],
]);

export default function SettingsSection() {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [collapsed, setCollapsed] = useState<boolean>(false);
	const [confirmOpenMenu, setConfirmOpenMenu] = useState<boolean>(false);
	const { campaignName, campaignID, linkTestNotification, network, linkTestNotificationLoading, maximusLinks } =
		useAppSelector(state => state.campaignDetails);

	const networkName = networkMap.get(network) || 'taboola';

	const updateEditSettingName = (setting: string) => {
		dispatch(changeCampaignDetails({ key: 'editSettingName', value: setting }));
		dispatch({ type: 'campaignDetails/openDialog' });
	};

	return (
		<div className={`menu-section ${collapsed ? 'menu-section-collapsed' : ''}`}>
			<SectionTitle
				title="Edit Settings"
				icon={<Settings className="menu-section-icon" />}
				onClick={() => setCollapsed(!collapsed)}
				collapsed={collapsed}
			/>
			<SectionButton text="Campaign Settings" onClick={() => updateEditSettingName('campaignSettings')} />
			<SectionButton
				text="Links"
				onClick={() => {
					if (linkTestNotification) {
						setConfirmOpenMenu(true);
					} else {
						updateEditSettingName('editLinks');
					}
				}}
				disabled={linkTestNotificationLoading}
			/>
			<SectionButton text="Fire & Forget™" onClick={() => updateEditSettingName('editFireAndForget')} />
			<SectionButton text="Day Parting" onClick={() => updateEditSettingName('editDayParting')} />
			<SectionButton text="Add Ads" onClick={() => updateEditSettingName('addAds')} />
			<SectionButton text="Reassign Campaign" onClick={() => updateEditSettingName('reassignCampaign')} />
			<SectionButton
				text="Clone Campaign"
				onClick={() => navigate(`/clone_campaign_${networkName}/${campaignName}`, { state: { campaignID } })}
			/>
			{!linkTestNotification && maximusLinks && (
				<SectionButton
					text="Start Link Test"
					onClick={() => updateEditSettingName('startLinkTest')}
					disabled={linkTestNotificationLoading}
				/>
			)}
			{confirmOpenMenu && (
				<DialogComponent
					title="Link Test is Running"
					dialogOpen
					useCancel={() => setConfirmOpenMenu(false)}
					cancelText="Close Popup"
					confirmText="Cancel Test"
					useConfirm={() => {
						return deactivateLinkTest(linkTestNotification, campaignID, network, dispatch).then(() => {
							setConfirmOpenMenu(false);
							updateEditSettingName('editLinks');
						});
					}}
				>
					<p>A link test is currently running. Before links can be edited, the test must be stopped.</p>
				</DialogComponent>
			)}
		</div>
	);
}
