import { useMemo } from 'react';
import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { useAppSelector } from '@/hooks.ts';
import { EstimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';
import { visitsAndClicks } from '@/utils/conditionalColumns.tsx';
import startCase from 'lodash/startCase';

const columns = [
	{
		field: 'browser',
		headerName: 'Browser',
		width: 175,
		valueFormatter: (value: string) => startCase(value),
	},
	{
		field: 'os',
		headerName: 'OS',
		width: 150,
	},
	...commonColumns([
		'visits',
		'clicks',
		'conversions',
		'average_cpc',
		'epc',
		'ctr',
		'cvr',
		'cpa',
		'roas',
		'revenue',
		'spend',
		'profit',
		'profit_percent',
	]),
];

export default function Browsers() {
	const { estimatedSpends, advancedTracking, maximusLinks } = useAppSelector(state => state.campaignDetails);

	const asyncColumns = useMemo(() => {
		return {
			...visitsAndClicks(true, advancedTracking, maximusLinks),
		};
	}, [advancedTracking, maximusLinks]);

	return (
		<div className="campaign-details-report">
			{!estimatedSpends && <EstimatedReportsDisclaimer />}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns}
				tableName="Browsers"
				endpoint="api/v1/campaign/reporting/browser"
				aggregationModel={{
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					epc: 'avg',
					maximusEpc: 'avg',
					average_cpc: 'avg',
					maximusAverageCpc: 'avg',
					ctr: 'avg',
					maximusCtr: 'avg',
					cpa: 'avg',
					maximusCpa: 'avg',
					profit: 'sum',
					profit_percent: 'profitPercent',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					cvr: 'avg',
					maximusCvr: 'avg',
					roas: 'avg',
					maximusRoas: 'avg',
					spend: 'sum',
					revenue: 'sum',
					browser: 'blankColumn',
					os: 'blankColumn',
				}}
			/>
		</div>
	);
}
