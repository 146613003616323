import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './DateRange.style.css'

import {useState, memo, MouseEvent, useRef} from 'react';
import {
	isSameDay, isSameYear, format, endOfYear, isWeekend,
	startOfDay, endOfDay, addDays, startOfMonth,
} from 'date-fns';
import {Popover, Button} from '@mui/material';
import {DateRangePicker, createStaticRanges} from 'react-date-range';
import {closeDialog, openDialog} from "../../Redux/Slices/main";
import DateRangeIcon from "../../Icons/DateRange";
import { twMerge } from "tailwind-merge";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {changeDate, changeFilter} from "../../Redux/Slices/filters";

export const PREDEFINED_RANGES_NAMES = {
	today: 'today',
	yesterday: 'yesterday',
	daysAgo2: 'two_days_ago',
	last7Days: 'last_seven_days',
	thisMonth: 'this_month',
	last30Days: 'last_thirty_days',
	lastMonth: 'last_month',
	monthsAgo2: 'two_months_ago',
	allTime: 'all_time',
};

const endOfToday = () => endOfDay(new Date());

export const PREDEFINED_RANGES = {
	[PREDEFINED_RANGES_NAMES.today]: {
		label: 'Today',
		range: () => ({
			startDate: startOfDay(new Date()),
			endDate: endOfToday(),
			range: PREDEFINED_RANGES_NAMES.today,
		}),
	},
	[PREDEFINED_RANGES_NAMES.yesterday]: {
		label: 'Yesterday',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -1)),
			endDate: endOfDay(addDays(new Date(), -1)),
			range: PREDEFINED_RANGES_NAMES.yesterday,
		}),
	},
	[PREDEFINED_RANGES_NAMES.daysAgo2]: {
		label: '2 Days Ago',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -2)),
			endDate: endOfDay(addDays(new Date(), -2)),
			range: PREDEFINED_RANGES_NAMES.daysAgo2,
		}),
	},
	[PREDEFINED_RANGES_NAMES.last7Days]: {
		label: 'Last 7 Days',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -6)),
			endDate: endOfToday(),
			range: PREDEFINED_RANGES_NAMES.last7Days,
		}),
	},
	[PREDEFINED_RANGES_NAMES.thisMonth]: {
		label: 'This Month',
		range: () => ({
			startDate: startOfMonth(new Date()),
			endDate: endOfToday(),
			range: PREDEFINED_RANGES_NAMES.thisMonth,
		}),
	},
	[PREDEFINED_RANGES_NAMES.last30Days]: {
		label: 'Last 30 Days',
		range: () => ({
			startDate: startOfDay(addDays(new Date(), -29)),
			endDate: endOfToday(),
			range: PREDEFINED_RANGES_NAMES.last30Days,
		}),
	},
	[PREDEFINED_RANGES_NAMES.allTime]: {
		label: 'All Time',
		range: () => ({
			range: PREDEFINED_RANGES_NAMES.allTime,
			startDate: startOfDay(new Date('5/1/2020')),
			endDate: endOfToday(),
		}),
	},
};


const WarningText = memo(function WarningText() {
	const dispatch = useAppDispatch();
	return (
		<p className="datepicker-warning-text">
			There is no data for this date with this week day filter.
			<button
				type="button"
				onClick={() => {
					dispatch(changeFilter({filter: 'weekDay', value: '-1'}));
					dispatch(closeDialog({open: false,}));
				}}
				className="cursor-pointer bg-transparent text-base text-[#2c8fff]"
			>
				Click to change to all days.
			</button>
		</p>
	);
});

const checkTodayWarning = (val, dispatch, dateRange) => {
	if (dateRange === 'today') {
		if (
			(val === 'weekends' && !isWeekend(new Date()))
			|| (val === 'week_days' && isWeekend(new Date()))
		) {
			dispatch(
				openDialog({
					severity: 'warning', children: <WarningText/>, title: 'Warning'
				})
			);
		}
	}
};

export default function DateRange({ className = '' }) {
	const customClicks = useRef(0);
	const [openPicker, setOpenPicker] = useState(false);
	const [anchorPicker, setAnchorPicker] = useState<EventTarget & Element>();
	const {startDate, endDate, dateRange, weekDay} = useAppSelector((state) => state.filters);
	const dispatch = useAppDispatch();

	const [dateA, setDateA] = useState(() => new Date(startDate));
	const [dateB, setDateB] = useState(() => new Date(endDate));

	const dispatchDateChanges = (newDate: { startDate: string; endDate: string; dateRange: string }) => {
		const storageKey = localStorage.getItem('userID') || 'dateRange';
		localStorage.setItem(storageKey, JSON.stringify(newDate));
		dispatch(changeDate(newDate));
	};

	const handleShowPicker = (open: boolean, event: MouseEvent) => {
		if (open) {
			setDateA(new Date(startDate));
			setDateB(new Date(endDate));
			customClicks.current = 0;
		}

		if (customClicks.current === 1) {
			dispatchDateChanges({
				startDate: dateA.toJSON(),
				endDate: dateB.toJSON(),
				dateRange: 'custom',
			});
		}

		setOpenPicker(open);
		setAnchorPicker(event.currentTarget || null);
	};

	const onRangeChange = ({ selection }) => {
		customClicks.current += 1;

		if (selection.range) {
			setDateA(selection.startDate);
			setDateB(selection.endDate);
		} else if (customClicks.current === 1) {
			setDateA(selection.startDate);
			setDateB(selection.startDate);
		} else if (customClicks.current === 2) {
			setDateB(selection.endDate);
		}

		if (selection.range || (!selection.range && customClicks.current === 2)) {
			dispatchDateChanges({
				startDate: selection.startDate.toJSON(),
				endDate: selection.endDate.toJSON(),
				dateRange: selection.range || 'custom',
			});
			setOpenPicker(false);
			customClicks.current = 0;
		}
		checkTodayWarning(weekDay, dispatch, selection.range);
	};

	const renderTitle = () => {
		const start = new Date(startDate);
		const end = new Date(endDate);
		const rangeFormat = `MM/dd${isSameYear(start, end) ? '' : '/yyyy'}`;
		const rangeText = isSameDay(start, end)
			? format(start, 'MM/dd/yyyy')
			: `${format(start, rangeFormat)} - ${format(end, rangeFormat)}`;
		const rangeLabel = (PREDEFINED_RANGES[dateRange]
			? PREDEFINED_RANGES[dateRange].label
			: PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today].label);
		return dateRange === 'custom' ? rangeText : rangeLabel;
	};

	return (
		<div className="w-full">
			<Button
				onClick={(event) => handleShowPicker(true, event)}
				className={twMerge("m-0 mr-4 h-full w-full justify-start bg-transparent text-base font-normal capitalize text-black/80", className)}
				id="datepicker-button"
			>
				<DateRangeIcon className="mr-3 h-6 w-6 fill-black/80" />
				{renderTitle()}
			</Button>
			<Popover
				open={openPicker}
				anchorEl={anchorPicker}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
				onClose={(event) => handleShowPicker(false, event as MouseEvent)}
			>
				<DateRangePicker
					onChange={onRangeChange}
					inputRanges={[]}
					ranges={[{
						startDate: dateA,
						endDate: dateB,
						key: 'selection',
					}]}
					staticRanges={createStaticRanges(
						Object.values(PREDEFINED_RANGES),
					)}
					maxDate={endOfYear(new Date())}
				/>
			</Popover>
		</div>
	);
}
