import Sparkles from '@/Icons/Sparkles';
import { Ad } from '@/Redux/Slices/addCampaign';
import { isRevContent } from '@/utils/network-utils';
import { Checkbox, Pagination, TablePagination, Tooltip } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { getCallToActionOptions } from '../AddCampaign/Pages/Ads/CallToActionOptions';

type CreativesListPickerProps = {
	creatives: (Ad & { searchTerm: string })[];
	selectedIds: string[];
	onSelect: (item: Ad & { searchTerm: string }) => void;
};

export default function CreativesListPicker({ creatives, selectedIds, onSelect }: CreativesListPickerProps) {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(12);
	const totalPages = Math.ceil(creatives.length / itemsPerPage);

	const startIndex = (currentPage - 1) * itemsPerPage;
	const paginatedCreatives = creatives.slice(startIndex, startIndex + itemsPerPage);

	const containerWidth = document.getElementById('creatives-list-picker')?.clientWidth || 0;
	useLayoutEffect(() => {
		if (containerWidth < 1010) setItemsPerPage(12);
		if (containerWidth >= 1010) setItemsPerPage(10);
		if (containerWidth >= 1212) setItemsPerPage(12);
		if (containerWidth >= 1414) setItemsPerPage(14);
		if (containerWidth >= 1618) setItemsPerPage(16);
		if (containerWidth >= 1823) setItemsPerPage(18);
		if (containerWidth >= 2028) setItemsPerPage(20);
	}, [containerWidth]);

	useEffect(() => {
		setCurrentPage(1);
	}, [creatives]);

	return (
		<div className="relative flex w-full flex-col gap-4" id="creatives-list-picker">
			<div className="grid w-full gap-3 auto-fill-48">
				{paginatedCreatives.map(ad => {
					const Thumbnail = ad.fileType === 'video' && !ad.image.includes('.gif') ? 'video' : 'img';
					const isSelected = selectedIds.includes(ad.uuid);
					return (
						<div
							key={ad.uuid}
							className={`group relative flex w-full cursor-pointer flex-col break-words rounded-md border border-black/20 p-2 text-sm shadow transition-all *:cursor-pointer hover:border-blue-400 ${isSelected && 'border-blue-500 bg-blue-50 shadow-blue-500/50'}`}
							onClick={() => onSelect(ad)}
						>
							<Thumbnail autoPlay loop muted src={ad.image} className="mb-1 max-h-48 rounded object-contain" />

							{!!ad.dicerFile && (
								<Tooltip title="This image was AI generated">
									<div className="group absolute right-3 top-3 flex items-center justify-center rounded-full bg-green-200/70 p-1 hover:bg-green-300/60">
										<Sparkles className="size-4 stroke-green-900" />
									</div>
								</Tooltip>
							)}

							<label className="mt-2 flex border-b text-xs font-semibold text-black/60">Headline</label>
							<p className="text-sm leading-tight">{ad.headline}</p>

							{!!ad.description && (
								<>
									<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Description</label>
									<p className="text-sm leading-tight">{ad.description}</p>
								</>
							)}

							{!isRevContent(ad.network!) && (
								<>
									<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Call to Action</label>
									<p className="text-sm leading-tight">
										{getCallToActionOptions(ad.network!).find(opt => ad.callToAction === opt.value)?.label}
									</p>
								</>
							)}

							<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Keywords</label>
							<p className="text-sm leading-tight">{ad.keywords?.join(', ')}</p>

							<div className="mt-auto">
								{ad.createdAt && (
									<div className="mt-3 text-xs leading-none text-black/70">
										<b className="font-medium">Created at:</b> {new Date(ad.createdAt).toLocaleString()}
									</div>
								)}
								{ad.creativeId && (
									<div className="mt-1.5 text-xs leading-none text-black/70">
										<b className="font-medium">ID:</b> {ad.creativeId}
									</div>
								)}
							</div>

							<Checkbox
								size="small"
								className={`absolute bottom-0 right-0 p-0.5 opacity-0 transition group-hover:opacity-100 ${isSelected && 'opacity-100'}`}
								checked={isSelected}
							/>
						</div>
					);
				})}
			</div>

			{totalPages > 1 && (
				<TablePagination
					className="absolute -top-12 right-0 m-0 border-none p-0"
					count={creatives.length}
					page={currentPage - 1}
					onPageChange={(_, page) => setCurrentPage(page + 1)}
					rowsPerPage={itemsPerPage}
					rowsPerPageOptions={[]}
					onRowsPerPageChange={e => {
						setItemsPerPage(parseInt(e.target.value, 10));
						setCurrentPage(1);
					}}
				/>
			)}

			{totalPages > 1 && (
				<Pagination
					className="ml-auto"
					count={totalPages}
					page={currentPage}
					onChange={(_, page) => setCurrentPage(page)}
					variant="outlined"
					shape="circular"
					size="small"
					showFirstButton
					showLastButton
				/>
			)}
		</div>
	);
}
