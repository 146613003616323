import { ReactElement, useEffect, useState } from 'react';
import Toggle from '../../../FormComponents/Toggle';
import { updateEstimatedSpends } from '../Requests/updateEstimatedSpends';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import DialogComponent from '../../../../utils/UIElements/Dialog';

const onText = (
	<div className="center">
		Are you sure you want to <strong>enable</strong> estimated spends?
		<br />
		<br />
		Reports from today, including Fire & Forget, will immediately use <strong>estimated data</strong> for today's
		traffic.
	</div>
);
const offText = (
	<div>
		Are you sure you want to <strong>disable</strong> estimated spends?
		<br />
		<br />
		Reports from today, including Fire & Forget, will use <strong>network data only</strong> for today's traffic.{' '}
		<strong>Maximus does not control this data.</strong>
	</div>
);

export default function EstimatedSpendToggle({
	loading,
	setLoading,
}: {
	loading: boolean;
	setLoading: (loading: boolean) => void;
}) {
	const dispatch = useAppDispatch();
	const [dialogOpen, setDialogOpen] = useState<boolean>(false);
	const [confirmText, setConfirmText] = useState<ReactElement>(<div></div>);
	const { campaignID, network, estimatedSpends, maximusLinks, campaignInfoLoading } = useAppSelector(
		state => state.campaignDetails
	);

	useEffect(() => {
		setConfirmText(estimatedSpends ? offText : onText);
	}, [estimatedSpends]);

	return maximusLinks ? (
		<>
			<Toggle
				label="Estimated Spends"
				trackColor="#4285F4"
				value={estimatedSpends}
				disabled={loading || campaignInfoLoading}
				onChange={() => setDialogOpen(true)}
				name="estimated-spends"
			/>
			<DialogComponent
				dialogOpen={dialogOpen}
				title="Estimated Spends"
				useConfirm={() => {
					setLoading(true);
					return updateEstimatedSpends(!estimatedSpends, dispatch, campaignID, network)
						.then(() => {
							setDialogOpen(false);
							setLoading(false);
						})
						.finally(() => setLoading(false));
				}}
				useCancel={() => setDialogOpen(false)}
			>
				{confirmText}
			</DialogComponent>
		</>
	) : (
		''
	);
}
