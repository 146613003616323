import Display from '@/Components/Pages/SocialDashboard/AIGeneration/Display.tsx';
import Generate from '@/Components/Pages/SocialDashboard/AIGeneration/Generate.tsx';

export default function AIGeneration() {
	return (
		<div className="border-1 rounded-[8px] border-solid border-gray-300 p-2 pb-3 shadow-md">
			<Generate />
			<Display />
		</div>
	);
}
