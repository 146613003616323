export const estimatedFieldsMap: { [key: string]: string } = {
	profit: 'maximusProfit',
	profit_percent: 'maximusProfitPercent',
	rpm: 'maximusRpm',
	clicks: 'maximusClicks',
	spend: 'estimatedSpend',
	conversions: 'maximusConversions',
	revenue: 'maximusRevenue',
	cvr: 'maximusCvr',
	epc: 'maximusEpc',
	roas: 'maximusRoas',
	cpa: 'maximusCpa',
	ctr: 'maximusCtr',
	average_cpc: 'maximusAverageCpc',
};
