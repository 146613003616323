import { createSlice } from '@reduxjs/toolkit';
import { Network } from '../../utils/network-utils';

export interface CampaignDetailsState {
	settingsDialogOpen: boolean;
	settingsDialogLoading: boolean;
	advancedTracking: boolean;
	campaignID: string;
	networkCampaignID: string;
	subAccountID: string;
	loading: boolean;
	isOtto: boolean;
	campaignStatus: boolean;
	campaignArchiveStatus: boolean;
	ffStatus: boolean;
	campaignName: string;
	chart: string;
	editSettingName: string;
	conversionCap: number;
	conversionCapTimeframe: string;
	network: Network;
	campaignNotifications: Array<string>;
	linkTestNotification: string;
	linkTestNotificationLoading: boolean;
	selectedUserID: string;
	selectedGroupID: string;
	startDate: string;
	updatedCampaignSettings: boolean;
	editedAdData: {
		cta: string;
		headline: string;
		description: string;
		adID: string;
		id: number;
	};
	hasSiteBids: boolean;
	hasCpc: boolean;
	previewLink: string;
	estimatedSpends: boolean;
	maximusLinks: boolean;
	clicksLinkTest: number;
	siteLevelTest: boolean;
	linkTestURLs: string[];
	blockingSubmitting: boolean;
	campaignInfoLoading: boolean;
}

export const defaultEdAdDataObj = {
	cta: 'NONE',
	headline: '',
	description: '',
	adID: '',
	id: 0,
};

export const initialState: CampaignDetailsState = {
	settingsDialogOpen: false,
	settingsDialogLoading: false,
	advancedTracking: false,
	campaignID: '',
	networkCampaignID: '',
	subAccountID: 'NA',
	network: Network.Taboola,
	loading: false,
	isOtto: false,
	campaignStatus: true,
	campaignArchiveStatus: false,
	ffStatus: true,
	campaignName: '',
	chart: 'ClicksAndConversions',
	editSettingName: '',
	conversionCap: 0,
	conversionCapTimeframe: '1',
	campaignNotifications: [],
	selectedUserID: '0',
	selectedGroupID: '0',
	startDate: '',
	updatedCampaignSettings: false,
	editedAdData: defaultEdAdDataObj,
	hasSiteBids: false,
	hasCpc: false,
	previewLink: '',
	linkTestNotification: '',
	linkTestNotificationLoading: false,
	estimatedSpends: false,
	maximusLinks: true,
	clicksLinkTest: 2,
	siteLevelTest: false,
	linkTestURLs: [],
	blockingSubmitting: false,
	campaignInfoLoading: true,
};

const campaignDetails = createSlice({
	name: 'campaignDetails',
	initialState,
	reducers: {
		changeCampaignDetails: (state, action = {}) => {
			state[action.payload.key] = action.payload.value;
		},
		toggleEstimatedSpends: state => {
			state.estimatedSpends = !state.estimatedSpends;
		},
		toggleCampaignStatus: state => {
			state.campaignStatus = !state.campaignStatus;
		},
		closeDialog: state => {
			state.settingsDialogOpen = false;
		},
		openDialog: state => {
			state.settingsDialogOpen = true;
		},
		resetCampaignDetails: state => {
			Object.assign(state, initialState);
		},
	},
});

export const { changeCampaignDetails, toggleCampaignStatus, toggleEstimatedSpends, resetCampaignDetails, closeDialog } =
	campaignDetails.actions;

export default campaignDetails.reducer;
