import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import type { AppDispatch } from '@/Redux/reduxStore.ts';
import networkRequest from '@/utils/networkRequest';

export function updateCampaignStatus(newStatus: boolean, dispatch: AppDispatch, campaignID: string, network: string, networkCampaignID: string, usmID: string, campaignArchiveStatus: boolean, setWarnings: (value: string[]) => void, setConfirms: (value: string[]) => void) {
	dispatch({ type: 'campaignDetails/toggleCampaignStatus' });
	return networkRequest('api/v1/campaign/status/update', { campaignID, network, status: newStatus, networkCampaignID, usmID })
		.then((response) => response.json())
		.then((response) => {
			if (!response.success) {
				dispatch({ type: 'campaignDetails/toggleCampaignStatus' });
				dispatch(openSnackbar(
					{
						children: response.message || 'Something went wrong. Please try again.',
						severity: 'error',
					},
				));
			} else {
				if (newStatus && campaignArchiveStatus) {
					dispatch(changeCampaignDetails({ key: 'campaignArchiveStatus', value: false }));
				}
				networkRequest('api/v1/campaign/details/notifications', { campaignID, network })
					.then(response => {
						if (response?.ok) return response.json();
						throw new Error('Error');
					})
					.then(campaignNotifications => {
						dispatch(changeCampaignDetails({ key: 'campaignNotifications', value: campaignNotifications || [] }));
					});
				if (response.warnings?.length || response.confirms?.length) {
					setWarnings(response.warnings || []);
					setConfirms(response.confirms || []);
				}
			}
		});
}
