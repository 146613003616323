export const EstimatedReportsDisclaimer = () => (
	<div className="w-full p-1 text-center font-bold">
		* This report is built <span className="underline">using estimates</span> so it may not match campaigns that use network data *
	</div>
);

export const LimitedResultsDisclaimer = ({ label }: { label: string }) => (
	<div className="w-full p-1 text-center font-bold">
		* This report <span className="underline">does not</span> honor the date range and will always return the last 100 {label} *
	</div>
);
