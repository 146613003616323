import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import DateRangeIcon from '@/Icons/DateRange';
import { Button, Popover } from '@mui/material';
import { endOfYear, format, isSameDay, isSameYear } from 'date-fns';
import { MouseEvent, useRef, useState } from 'react';
import { createStaticRanges, DateRangePicker as DateRangePickerBase } from 'react-date-range';
import { twMerge } from 'tailwind-merge';
import { PREDEFINED_RANGES, PREDEFINED_RANGES_NAMES } from './DateRange';

type DateRangeBaseProps = {
	className?: string;
	startDate: string;
	endDate: string;
	range: string;
	onRangeChange: (newRange: { startDate: string; endDate: string; range: string }) => void;
};

export default function DateRangeBase({ className, startDate, endDate, range, onRangeChange }: DateRangeBaseProps) {
	const customClicks = useRef(0);
	const [openPicker, setOpenPicker] = useState(false);
	const [anchorPicker, setAnchorPicker] = useState<EventTarget & Element>();

	const [dateA, setDateA] = useState(() => new Date(startDate));
	const [dateB, setDateB] = useState(() => new Date(endDate));

	const handleShowPicker = (open: boolean, event: MouseEvent) => {
		if (open) {
			setDateA(new Date(startDate));
			setDateB(new Date(endDate));
			customClicks.current = 0;
		}

		if (customClicks.current === 1) {
			onRangeChange({
				startDate: dateA.toJSON(),
				endDate: dateB.toJSON(),
				range: 'custom',
			});
		}

		setOpenPicker(open);
		setAnchorPicker(event.currentTarget || null);
	};

	const handleOnChange = ({ selection }) => {
		customClicks.current += 1;

		if (selection.range) {
			setDateA(selection.startDate);
			setDateB(selection.endDate);
		} else if (customClicks.current === 1) {
			setDateA(selection.startDate);
			setDateB(selection.startDate);
		} else if (customClicks.current === 2) {
			setDateB(selection.endDate);
		}

		if (selection.range || (!selection.range && customClicks.current === 2)) {
			onRangeChange({
				startDate: selection.startDate.toJSON(),
				endDate: selection.endDate.toJSON(),
				range: selection.range || 'custom',
			});
			setOpenPicker(false);
			customClicks.current = 0;
		}
	};

	const renderTitle = () => {
		if (range !== 'custom') {
			return PREDEFINED_RANGES[range]
				? PREDEFINED_RANGES[range].label
				: PREDEFINED_RANGES[PREDEFINED_RANGES_NAMES.today].label;
		}
		const start = new Date(startDate);
		const end = new Date(endDate);
		const rangeFormat = `MM/dd${isSameYear(start, end) ? '' : '/yyyy'}`;
		return isSameDay(start, end)
			? format(start, 'MM/dd/yyyy')
			: `${format(start, rangeFormat)} - ${format(end, rangeFormat)}`;
	};

	return (
		<>
			<Button
				className={twMerge(
					'm-0 mr-4 h-full w-full justify-start bg-transparent text-base font-normal capitalize text-black/80',
					className
				)}
				onClick={event => handleShowPicker(true, event)}
				id="datepicker-button"
			>
				<DateRangeIcon className="mr-3 h-6 w-6 fill-black/80" />
				{renderTitle()}
			</Button>

			<Popover
				open={openPicker}
				anchorEl={anchorPicker}
				anchorOrigin={{
					horizontal: 'left',
					vertical: 'bottom',
				}}
				onClose={event => handleShowPicker(false, event as MouseEvent)}
			>
				<DateRangePickerBase
					onChange={handleOnChange}
					inputRanges={[]}
					ranges={[
						{
							startDate: dateA,
							endDate: dateB,
							key: 'selection',
						},
					]}
					staticRanges={createStaticRanges(Object.values(PREDEFINED_RANGES))}
					maxDate={endOfYear(new Date())}
				/>
			</Popover>
		</>
	);
}
