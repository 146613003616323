import { PREDEFINED_RANGES } from '@/Components/Filters/DateRange';
import DateRangeBase from '@/Components/Filters/DateRangeBase';
import MaximusLoader from '@/Components/Loaders/MaximusLoader';
import { useAppDispatch } from '@/hooks';
import Sparkles from '@/Icons/Sparkles';
import { openSnackbar } from '@/Redux/Slices/main';
import { useCancellableNetworkRequest } from '@/utils/networkRequest';
import { Tooltip } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router';
import { twMerge } from 'tailwind-merge';
import FieldContainer from '../AddCampaign/Components/FieldContainer';

function getDefaultFilters() {
	const data = PREDEFINED_RANGES.last_thirty_days.range();
	return {
		startDate: data.startDate.toJSON(),
		endDate: data.endDate.toJSON(),
		range: data.range,
	};
}

export default function TopAds() {
	const dispatch = useAppDispatch();
	const cancellableRequest = useCancellableNetworkRequest();
	const [ads, setAds] = useState<Record<string, any>[]>([]);
	const [loading, setLoading] = useState(false);
	const [filters, setFilters] = useState(getDefaultFilters());

	useEffect(() => {
		setLoading(true);
		cancellableRequest('api/v1/campaign/ad/pull/top_by_epc', {
			startDate: filters.startDate,
			endDate: filters.endDate,
			dateRange: filters.range,
		})
			.then(response => response?.json())
			.then(data => {
				if (!data?.topAds) throw new Error('No ads found');
				setAds(data.topAds);
			})
			.catch(e => {
				setAds([]);
				dispatch(openSnackbar({ severity: 'error', children: 'Failed to fetch top ads' }));
			})
			.finally(() => setLoading(false));
	}, [cancellableRequest, dispatch, filters]);

	return (
		<div className="h-full space-y-3 overflow-y-scroll p-2">
			<FieldContainer className="flex flex-col p-5">
				<header className="mb-4 flex w-full items-center gap-4 border-b pb-1">
					<h1 className="mr-auto text-2xl font-semibold text-black/60">
						Top Ads
						<p className="mt-2 text-sm font-semibold text-black/40">
							(Ads filtered by having at least 100 clicks, with the highest EPC, CTR, and lowest CPC - sorted
							highest to lowest for the selected date range)
						</p>
					</h1>

					<DateRangeBase
						className={twMerge(
							'm-0 w-52',
							'rounded-b-none border-b border-solid border-b-black/[0.42] bg-black/[0.06]'
						)}
						startDate={filters.startDate}
						endDate={filters.endDate}
						range={filters.range}
						onRangeChange={({ startDate, endDate, range }) => {
							setFilters({ ...filters, startDate, endDate, range });
						}}
					/>
				</header>

				{loading ? (
					<MaximusLoader />
				) : (
					<div className="grid w-full gap-4 auto-fill-56 xl:grid-cols-5">
						{ads.map(ad => (
							<AdItem key={ad.ad_id} ad={ad} />
						))}
					</div>
				)}
			</FieldContainer>
		</div>
	);
}

function AdItem({ ad }: { ad: Record<string, any> }) {
	return (
		<div
			key={ad.uuid}
			className="group relative flex w-full flex-col break-words rounded-md border border-black/20 p-2 text-sm shadow"
		>
			<img src={ad.thumbnail_url} className="mb-1 max-h-48 rounded object-contain" alt="image" />

			{!!ad.dicer_url && (
				<Tooltip title="This image was AI generated">
					<div className="group absolute right-3 top-3 flex items-center justify-center rounded-full bg-green-200/70 p-1 hover:bg-green-300/60">
						<Sparkles className="size-4 stroke-green-900" />
					</div>
				</Tooltip>
			)}

			<Entry label="Headline" className="mt-2">{ad.title}</Entry>
			<Entry label="Description">{ad.description || 'N/A'}</Entry>
			<div className="flex gap-4">
				<Entry label="EPC">${ad.epc.toFixed(2)}</Entry>
				<Entry label="CPC">${ad.cpc.toFixed(2)}</Entry>
				<Entry label="CTR">{(+ad.ctr * 100).toFixed(2)}%</Entry>
			</div>

			<div className="mt-auto">
				<div className="mt-3 text-xs leading-none text-black/70">
					<Link to={`/campaign_details/${ad.campaign_name}`}>{ad.campaign_name}</Link>
				</div>

				<div className="mt-1.5 text-xs leading-none text-black/70">
					<b className="font-medium">Created at:</b> {new Date(ad.created_at).toLocaleString()}
				</div>
			</div>
		</div>
	);
}

function Entry({ children, label, className }: { children: ReactNode; label: string; className?: string }) {
	return (
		<div className={twMerge('mt-3 w-full', className)}>
			<label className="flex border-b text-xs font-semibold text-black/60">{label}</label>
			<p className="text-sm leading-tight">{children}</p>
		</div>
	);
}
