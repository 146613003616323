import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SocialState {
	aiGeneratedImages: Array<{ image: string; headline: string }>;
}

const initialState: SocialState = {
	aiGeneratedImages: [],
};

const socialSlice = createSlice({
	name: 'social',
	initialState,
	reducers: {
		updateSocialStore: (state, action: PayloadAction<Partial<SocialState>>) => {
			return { ...state, ...action.payload };
		},
	},
});

export const { updateSocialStore } = socialSlice.actions;

export default socialSlice.reducer;
