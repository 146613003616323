import { useAppDispatch } from '@/hooks';
import Close from '@/Icons/Close';
import { updateDataGridCustomization } from '@/Redux/Slices/main';
import IssueReport from '@/utils/reporting/IssueReport';
import { Button, Tooltip } from '@mui/material';
import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
	GridToolbarQuickFilter,
	useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import { columnsStorageName } from './Helpers/ColumnReorderHelper';

type CustomToolBarProps = {
	tableName: string;
	dateRangeTitle: string;
	refreshRef: number;
	exportAllColumns: boolean;
	lockedVisibilityModel: Record<string, boolean>;
};

export function CustomToolBar({
	tableName,
	dateRangeTitle,
	refreshRef,
	exportAllColumns,
	lockedVisibilityModel,
}: CustomToolBarProps) {
	const dispatch = useAppDispatch();
	const apiRef = useGridApiContext();
	const [hiddenColCount, setHiddenColCount] = useState(0);
	const columnVisibilityModel = apiRef.current.store.value.columns.columnVisibilityModel;

	useEffect(() => {
		const hiddenColumns = Object.entries(columnVisibilityModel).filter(
			([key, value]) => value === false && lockedVisibilityModel[key] !== false
		);
		setHiddenColCount(hiddenColumns.length);
	}, [columnVisibilityModel, lockedVisibilityModel]);

	const forceRefreshedCont = () => {
		localStorage.removeItem(columnsStorageName(tableName));
		dispatch(updateDataGridCustomization({ forceRefreshCount: refreshRef + 1 }));
	};

	return (
		<GridToolbarContainer className="relative justify-end border-b border-black/10 p-3 text-black">
			{tableName && <span className="mr-auto text-xl font-semibold text-black/50">{tableName}</span>}

			<GridToolbarQuickFilter />
			<GridToolbarFilterButton />
			<GridToolbarDensitySelector />

			<div className="relative">
				<GridToolbarColumnsButton />
				{hiddenColCount > 0 && (
					<Tooltip title={hiddenColCount > 0 && `${hiddenColCount} hidden columns`}>
						<div className="absolute -top-1.5 left-3 flex size-5 select-none items-center justify-center rounded-full bg-[#1976d2] text-xs text-white">
							{hiddenColCount}
						</div>
					</Tooltip>
				)}
			</div>

			<GridToolbarExport
				csvOptions={{
					fileName: `${tableName} Report ${dateRangeTitle}`,
					allColumns: exportAllColumns,
				}}
			/>

			{localStorage.getItem(columnsStorageName(tableName)) && (
				<Button size="small" onClick={forceRefreshedCont}>
					<Close className="mr-1 h-5 w-5 fill-[#1976d2]" />
					Reset Column Order
				</Button>
			)}

			<IssueReport iconClassName="size-4" color="primary" label={tableName} />
		</GridToolbarContainer>
	);
}
