import { useEffect, useMemo, useState } from 'react';
import difference from 'lodash/difference.js';
import uniq from 'lodash/uniq.js';
import HoursAutocomplete from './HoursAutocomplete';
import {
	createTimeString,
	daysMapping,
	renderLaterTimes,
	returnDayPartingErrors,
	weekDays,
	weekEnds,
} from './dayPartingHelper';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';
import Input from '../../Fields/Input';
import { addToDayParting, addToNetworkDayParting, changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import Toggle from '../../../../FormComponents/Toggle';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { checkConfirmBudget } from '../../../CampaignDetails/Settings/CampaignSettings/useSubmitSettings.tsx';
import DialogComponent from '../../../../../utils/UIElements/Dialog.tsx';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { ErrorList } from '@/utils/useErrorHelper.tsx';
import { isOutbrain } from '@/utils/network-utils.ts';

export default function AddSettings({ campaignDetails }: { campaignDetails: boolean }): JSX.Element {
	const dispatch = useAppDispatch();
	const { hasCpc, campaignID, network } = useAppSelector(state => state.campaignDetails);
	const {
		settings,
		maximusDayParting,
		dayParting: currentDayParting,
		networkDayParting: currentNetworkDayParting,
	} = useAppSelector(state => state.addCampaign);
	const showCPCField =
		(!campaignDetails &&
			['0', '1', '2'].includes(settings[isOutbrain(network) ? 'optimizationType' : 'bidStrategy'])) ||
		(campaignDetails && hasCpc);
	const nonCPCOutbrainDayparting = !maximusDayParting && isOutbrain(network) && !showCPCField;

	const [days, setDays] = useState<Array<string>>([]);
	const [startAt, setStartAt] = useState<string>('0');
	const [pauseAt, setPauseAt] = useState<string>('0');
	const [budget, setBudget] = useState<string>('');
	const [cpc, setCPC] = useState<string>('');
	const [enableBudget, setEnableBudget] = useState<boolean>(true);
	const [enableCPC, setEnableCPC] = useState<boolean>(showCPCField);
	const [pauseCampaign, setPauseCampaign] = useState<boolean>(false);
	const [errors, setErrors] = useState<Record<string, any>>({});
	const [confirmBudgetChangeMessage, setConfirmBudgetChangeMessage] = useState<string>('');

	useEffect(() => {
		resetFields();
	}, [maximusDayParting]);

	useEffect(() => {
		checkForNonCPCOutbrainDayparting();
	}, [nonCPCOutbrainDayparting]);

	const resetFields = () => {
		setDays([]);
		setStartAt('0');
		setPauseAt('0');
		setBudget('');
		setEnableBudget(true);
		setCPC('');
		setEnableCPC(showCPCField);
		setPauseCampaign(false);
		checkForNonCPCOutbrainDayparting();
	};

	const checkForNonCPCOutbrainDayparting = () => {
		if (nonCPCOutbrainDayparting) {
			setPauseCampaign(true);
			setEnableCPC(false);
			setEnableBudget(false);
			setErrors({ ...errors, cpc: '', budget: '' });
		}
	};

	const storeDayParting = (currentBudget = budget) => {
		dispatch(
			addToDayParting({
				value: {
					id: crypto.randomUUID(),
					days,
					startAt,
					pauseAt,
					budget: currentBudget,
					cpc,
					enableBudget,
					enableCPC,
					unixPauseAt: new Date(`2023-01-01 ${createTimeString(pauseAt)}:00`).getTime(),
				},
			})
		);
	};

	const storeNetworkDayParting = () =>
		dispatch(
			addToNetworkDayParting({
				value: {
					id: crypto.randomUUID(),
					days,
					startAt,
					pauseAt,
					status: !pauseCampaign,
					cpc,
					enableCPC,
					unixPauseAt: new Date(`2023-01-01 ${createTimeString(pauseAt)}:00`).getTime(),
				},
			})
		);

	const validateBudgetCPC = () => {
		let newConfirmBudgetMessage = '';
		if (showCPCField && enableCPC && enableBudget) {
			newConfirmBudgetMessage = checkConfirmBudget({ network, budget, cpc, bidStrategy: settings.bidStrategy });
		}
		if (enableBudget && !enableCPC && showCPCField) {
			newConfirmBudgetMessage = checkConfirmBudget({
				network,
				budget,
				cpc: settings.cpc,
				bidStrategy: settings.bidStrategy,
			});
		}
		if (!enableBudget && enableCPC) {
			newConfirmBudgetMessage = checkConfirmBudget({
				network,
				budget: settings.budget,
				cpc,
				bidStrategy: settings.bidStrategy,
			});
		}
		setConfirmBudgetChangeMessage(newConfirmBudgetMessage);
		return !newConfirmBudgetMessage;
	};

	const validate = () => {
		const dayPartingObj = {
			days,
			startAt,
			pauseAt,
			budget,
			cpc,
			enableBudget,
			enableCPC,
			settings,
		};

		const { valid, errors: validationErrors } = returnDayPartingErrors(
			dayPartingObj,
			network,
			showCPCField,
			maximusDayParting,
			maximusDayParting ? currentDayParting : currentNetworkDayParting
		);

		if (!valid) {
			setErrors(validationErrors);
			const children = (
				<ErrorList>
					{Object.keys(validationErrors).map((key, i: number) => (
						<li key={`error-${i}`}>{validationErrors[key]}</li>
					))}
				</ErrorList>
			);
			dispatch(openSnackbar({ children, severity: 'error' }));
			return;
		} else {
			setErrors({});
			if (maximusDayParting) {
				return validateBudgetCPC();
			}
			return true;
		}
	};

	async function save() {
		if (!validate()) {
			return;
		}
		if (maximusDayParting) {
			storeDayParting();
		} else {
			storeNetworkDayParting();
		}
		resetFields();
		dispatch(openSnackbar({ children: 'Day Parting Setting Added', severity: 'success' }));
	}

	const updateBudgetRequest = async (newBudget: string) =>
		await networkRequest('api/v1/campaign/settings/budget/update', {
			campaignID,
			network,
			budget: newBudget,
		})
			.then(response => response.json())
			.then(response => {
				if (response.success) {
					dispatch(openSnackbar({ children: 'Budget was updated successfully.', severity: 'success' }));
					dispatch(changeSettingsField({ name: 'budget', value: newBudget }));
				}
			});

	const renderWeekDayButtons = useMemo(() => {
		const weekDaysSelected = difference(weekDays, days).length === 0;

		const weekEndsSelected = difference(weekEnds, days).length === 0;

		const setDaysByType = (day: string) => {
			if (day === 'week days') {
				if (difference(weekDays, days).length === 0) {
					setDays<Array<object>>(difference(days, weekDays));
				} else {
					setDays<Array<object>>(uniq([...days, ...weekDays]));
				}
				return true;
			}
			if (difference(weekEnds, days).length === 0) {
				setDays<Array<object>>(difference(days, weekEnds));
			} else {
				setDays<Array<object>>(uniq([...days, ...weekEnds]));
			}
			return true;
		};
		const formatSetDay = (selectedDay: string) => {
			if (days.indexOf<string>(selectedDay) > -1) {
				setDays(days.filter(day => day !== selectedDay));
			} else {
				setDays([...days, selectedDay]);
			}
		};
		return Object.values(daysMapping<object>).map(day => (
			<button
				type="button"
				key={day}
				onClick={() => {
					if (day.includes('week')) setDaysByType(day);
					else formatSetDay(day);
					setErrors({ ...errors, days: '' });
				}}
				className={`weekday-button ${days.indexOf(day) > -1 ? 'selected-day-parting-day' : ''} ${day === 'week days' && weekDaysSelected ? 'selected-day-parting-day' : ''} ${day === 'week ends' && weekEndsSelected ? 'selected-day-parting-day' : ''}`}
			>
				{day}
			</button>
		));
	}, [days, errors]);

	const confirmChangeBudget = async () => {
		const calcCPC = enableCPC ? cpc : settings.cpc;
		const newBudget = (Number(calcCPC) * 30).toString();
		if (enableBudget) {
			setBudget(newBudget);
		} else {
			if (!campaignDetails) {
				dispatch(changeSettingsField({ name: 'budget', value: newBudget || 0 }));
			} else {
				await updateBudgetRequest(newBudget);
			}
		}
		setConfirmBudgetChangeMessage('');
		storeDayParting(newBudget);
		resetFields();
		dispatch(openSnackbar({ children: 'Day Parting Setting Added', severity: 'success' }));
	};

	return (
		<div className="day-parting-container">
			<div className="week-day-button-container">{renderWeekDayButtons}</div>

			{(maximusDayParting || isOutbrain(network)) && (
				<div className="add-campaign-field-row pause-campaign-toggle">
					<Toggle
						trackColor="#4285F4"
						value={pauseCampaign}
						label="Pause Campaign"
						onChange={checked => {
							setPauseCampaign(checked);
							if (checked) {
								setEnableCPC(false);
								setEnableBudget(false);
								setErrors({ ...errors, cpc: '', budget: '' });
							}
						}}
						disabled={nonCPCOutbrainDayparting}
						name="pause-campaign"
					/>
				</div>
			)}
			<div className="add-campaign-field-row">
				<HoursAutocomplete
					onValueChange={value => {
						if (value >= startAt) {
							setStartAt('0');
						}
						setPauseAt(value);
						setErrors({ ...errors, pauseAt: '' });
					}}
					label={pauseCampaign || !maximusDayParting ? `Pause At` : 'Start At'}
					value={pauseAt}
					helperText={errors.pauseAt}
					error={Boolean(errors.pauseAt)}
					options={renderLaterTimes(pauseAt, maximusDayParting)}
					className="m-3 w-full"
					data-testid="select-pause-at"
				/>
				<HoursAutocomplete
					onValueChange={value => {
						setStartAt(value);
						setErrors({ ...errors, startAt: '' });
					}}
					label={pauseCampaign || !maximusDayParting ? `Start At` : 'End At'}
					value={startAt}
					helperText={errors.startAt}
					error={Boolean(errors.startAt)}
					options={renderLaterTimes(pauseAt, maximusDayParting, true)}
					className="m-3 w-full"
					data-testid="select-start-at"
				/>
			</div>
			<div className="add-campaign-field-row">
				{maximusDayParting && (
					<Input
						onChange={({ target: { value } }) => {
							setBudget(value);
							setErrors({ ...errors, budget: '' });
						}}
						label="Budget"
						value={budget}
						name="day-parting-budget"
						helperText={errors.budget}
						error={Boolean(errors.budget)}
						numeric
						startAdornment={<span>$</span>}
						size={{ m: '12px', width: 'calc(50.33% - 24px)' }}
						disabled={!enableBudget}
						endAdornment={
							<Toggle
								trackColor="#4285F4"
								value={enableBudget}
								onChange={checked => {
									setEnableBudget(checked);
									setErrors({ ...errors, budget: '' });
								}}
								name="enable-budget"
								disabled={pauseCampaign}
							/>
						}
					/>
				)}
				{(maximusDayParting || isOutbrain(network)) && showCPCField && (
					<Input
						onChange={({ target: { value } }) => {
							setCPC(value);
							setErrors({ ...errors, cpc: '' });
						}}
						label="CPC"
						value={cpc}
						numeric
						helperText={errors.cpc}
						error={Boolean(errors.cpc)}
						name="day-parting-cpc"
						startAdornment={<span>$</span>}
						size={{ m: '12px', width: 'calc(50.33% - 24px)' }}
						disabled={!enableCPC}
						endAdornment={
							<Toggle
								trackColor="#4285F4"
								value={enableCPC}
								onChange={checked => {
									setEnableCPC(checked);
									setErrors({ ...errors, cpc: '' });
								}}
								name="enable-cpc"
								disabled={pauseCampaign}
							/>
						}
					/>
				)}
			</div>
			{!!confirmBudgetChangeMessage && (
				<DialogComponent
					dialogOpen
					title="Confirm"
					useConfirm={confirmChangeBudget}
					useCancel={() => setConfirmBudgetChangeMessage('')}
				>
					<p>{confirmBudgetChangeMessage}</p>
				</DialogComponent>
			)}
			<button type="button" className="add-day-parting-button" onClick={save}>
				Create Day Parting Setting
			</button>
		</div>
	);
}
