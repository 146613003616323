import { useMemo, useState } from 'react';
import { GridColDef } from '@mui/x-data-grid-premium';
import { Button } from '@mui/material';
import { format } from 'date-fns';
import { commonColumns } from '@/utils/commonColumns.tsx';
import { hoursComparator } from '@/utils/tables-utils.tsx';
import ReportTable from '../../../../DataTable/ReportTable.tsx';
import { visitsAndClicks } from '@/utils/conditionalColumns.tsx';
import { useAppSelector } from '@/hooks.ts';
import { EstimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';
import { isTaboola } from '@/utils/network-utils.ts';

export default function ByDate() {
	const { estimatedSpends, maximusLinks, advancedTracking , network } = useAppSelector(state => state.campaignDetails);
	const [hoursReport, toggleHoursReport] = useState<boolean>(false);
	const [selectedDay, setSelectedDay] = useState<string>('');

	const renderDateButton = params => (
		<Button
			onClick={() => {
				setSelectedDay(params.value);
				toggleHoursReport(true);
			}}
		>
			{params.row.date}
		</Button>
	);

	const columns = {
		days: [
			{
				field: 'date',
				headerName: 'Date',
				width: 120,
				type: 'date',
				renderCell: renderDateButton,
				valueGetter: (_value, row) => (row && row.date ? new Date(row.date) : ''),
			},
			...commonColumns([
				'impressions',
				'visits',
				'clicks',
				'spend',
				'revenue',
				'ctr',
				'conversions',
				'cvr',
				'cpa',
				'epc',
				'average_cpc',
				'profit',
				'profit_percent',
			]),
		] satisfies GridColDef[],
		hours: [
			{
				field: 'hour',
				headerName: 'Hour',
				width: 100,
				sortComparator: hoursComparator,
			},
			...commonColumns([
				'impressions',
				'visits',
				'clicks',
				'spend',
				'revenue',
				'ctr',
				'conversions',
				'cvr',
				'cpa',
				'epc',
				'average_cpc',
				'profit',
				'profit_percent',
			]),
		] satisfies GridColDef[],
	};

	const asyncColumns = useMemo(() => {
		return {
			...visitsAndClicks(hoursReport ? true : estimatedSpends, advancedTracking, maximusLinks),
		};
	}, [advancedTracking, estimatedSpends, maximusLinks]);

	return (
		<div className="campaign-details-report">
			{hoursReport && (
				<Button className="campaign-details-report-back-button" onClick={() => toggleHoursReport(false)}>
					Back To By Date Report
				</Button>
			)}
			{!estimatedSpends && hoursReport && !isTaboola(network) && <EstimatedReportsDisclaimer />}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns[hoursReport ? 'hours' : 'days']}
				tableName={hoursReport ? `By Hour For ${format(selectedDay, 'MM/dd/yyyy')}` : 'By Date'}
				tableKey={hoursReport ? 'By Hour' : 'By Date'}
				additionalRequestParams={
					hoursReport
						? {
								startDate: format(selectedDay, 'yyyy-MM-dd'),
								endDate: format(selectedDay, 'yyyy-MM-dd'),
								dateRange: 'custom',
							}
						: {}
				}
				endpoint={hoursReport ? 'api/v1/campaign/reporting/byDate/byHour' : 'api/v1/campaign/reporting/byDate'}
			/>
		</div>
	);
}
