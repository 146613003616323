import {useEffect, lazy, useState, Suspense} from 'react'

function Loading() {
	return (
		<div></div>
	)
}

function ClientOnly(props: { component: () => Promise<{ default: React.ComponentType<any> }> }) {
	const [Component, setComponent] = useState<unknown>(() => Loading);

	useEffect(() => {
		setComponent(() => lazy(props.component));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Suspense fallback={<Loading />}>
			<Component />
		</Suspense>
	)
}

export { ClientOnly }
