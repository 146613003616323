import ChartsSection from './ChartsSection';
import FilterSection from './FilterSection';
import InfoSection from './InfoSection.tsx';
import ReportSection from './ReportSection';
import SettingsSection from './SettingsSection';

export default function Menu() {
	return (
		<div className="h-full w-full overflow-x-hidden overflow-y-scroll border-r border-black/80 bg-white px-0 py-2">
			<FilterSection />
			<ChartsSection />
			<ReportSection />
			<SettingsSection />
			<InfoSection />
			<div className="mb-12" />
		</div>
	);
}
