import Input from '@/Components/Pages/AddCampaign/Fields/Input.tsx';
import Select from '@/Components/Pages/AddCampaign/Fields/Select.tsx';
import { Dicer, useAiGenerate } from '@/Components/Pages/AddCampaign/Pages/Ads/useAiGenerate.ts';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { updateSocialStore } from '@/Redux/Slices/social.ts';
import networkRequest from '@/utils/networkRequest';
import { Alert, Button, CircularProgress, Dialog } from '@mui/material';
import { useMemo, useState } from 'react';

const mediaTypeOptions = [
	{ value: 'image', label: 'Image' },
	{ value: 'video', label: 'Video' },
];

const variantOptions = new Array(10)
	.fill(null)
	.map((_, i) => ({ value: (i + 1).toString(), label: (i + 1).toString() }));

const offerOptions = [
	{ value: Dicer.offerId.vibriance, label: 'Vibriance' },
	{ value: 'cm8d6c8mf0001rx51wq7vfln0', label: 'Walk-In Tub' },
	{ value: 'cm8g4lmi6000dykp84qi3cea5', label: 'Window Replacement' },
	{ value: 'cc12ff37aa700bf18f2f319a2', label: 'OTTO Auto' },
	{ value: 'cm5f5w7by00031wzu06mfenr0', label: 'OTTO Home' },
];

const aspectRatioOptions = [
	{ value: '1:1', label: '1:1' },
	{ value: '16:9', label: '16:9' },
	{ value: '2:3', label: '2:3' },
	{ value: '3:2', label: '3:2' },
	{ value: '4:5', label: '4:5' },
	{ value: '5:4', label: '5:4' },
	{ value: '9:16', label: '9:16' },
	{ value: '3:4', label: '3:4' },
	{ value: '4:3', label: '4:3' },
];

const actorOptions = [
	{ value: 'Janet', label: 'Janet' },
	{ value: 'Violet', label: 'Violet' },
	{ value: 'random', label: 'Random Actor' },
];

const getDefaultFormValues = () => ({
	mediaType: 'image',
	prompt: '',
	variants: '3',
	offer: Dicer.offerId.vibriance,
	aspectRatio: '16:9',
	actor: '',
});

export default function Generate() {
	const dispatch = useAppDispatch();
	const [form, setForm] = useState(getDefaultFormValues());
	const { email } = useAppSelector(state => state.main);
	const [videoLoading, setVideoLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const [rejectedVideos, setRejectedVideos] = useState(0);
	const aiImageGenerate = useAiGenerate();
	const loading = aiImageGenerate.loading || videoLoading;

	const disabledSubmit = useMemo(() => {
		return loading || (form.mediaType === 'video' && !form.actor);
	}, [form, loading]);

	function handleGenerateClick() {
		if (form.mediaType === 'image') generateImages();
		else generateVideos(+form.variants);
	}

	const generateImages = async () => {
		const { images } = await aiImageGenerate.fetchAiData({
			variants: parseInt(form.variants),
			descriptionsPrompt: '',
			headlinesPrompt: '',
			imagesPrompt: form.prompt,
			useAi: { images: true, headlines: false, descriptions: false },
			aspectRatio: form.aspectRatio as any,
			overrideOfferId: form.offer,
		});
		if (images?.length) {
			const aiGeneratedImages = images.map(({ image }) => ({ image, headline: '' }));
			dispatch(updateSocialStore({ aiGeneratedImages }));
		} else {
			dispatch(
				openSnackbar({
					children: 'Failed to generate images. Please try again, or open a support ticket if this problem continues',
					severity: 'error',
				})
			);
		}
	};

	async function generateVideos(count: number) {
		setVideoLoading(true);

		const getVideoRequest = () =>
			networkRequest('api/v1/campaign/ad/ai_generate/video', {
				offer_id: form.offer,
				actor: form.actor,
				email: email || null,
			})
				.then(response => {
					if (!response.ok) throw new Error('Failed to generate video');
					return response.json();
				})
				.then(data => {
					console.log(data);
				});

		const promises = new Array(count)
			.fill(null)
			.map((_, index) => new Promise(resolve => setTimeout(resolve, index * 3000)).then(() => getVideoRequest()));

		Promise.allSettled(promises)
			.then(res => {
				const rejectedCount = res.filter(({ status }) => status === 'rejected').length;
				if (rejectedCount === count) {
					dispatch(openSnackbar({ children: 'Failed to generate videos. Please try again.', severity: 'error' }));
					return;
				}
				setRejectedVideos(rejectedCount);
				setModalOpen(true);
			})
			.finally(() => {
				setVideoLoading(false);
			});
	}

	function handleDialogConfirm() {
		setModalOpen(false);
		setRejectedVideos(0);
		setForm(getDefaultFormValues());
	}

	return (
		<div className="p-4 pt-6">
			<h1 className="border-b pb-2 text-2xl font-semibold text-black/60">AI Image Generation</h1>

			<section className="my-6 grid grid-cols-1 gap-6 md:grid-cols-2">
				<Select
					name="MediaType"
					label="Media Type"
					helperText="Select the type of generated media"
					size={{ m: 0, width: '100%' }}
					options={mediaTypeOptions}
					value={form.mediaType}
					onChange={e => {
						const mediaValue = e.target.value;
						setForm(prev => ({
							...prev,
							actor: '',
							mediaType: mediaValue,
							variants: mediaValue === 'video' ? '1' : prev.variants,
						}));
					}}
				/>

				<Input
					multiline
					name="AIPrompt"
					label="AI Prompt (optional)"
					helperText={
						form.mediaType === 'video'
							? 'Video generation does not support prompts'
							: 'Enter a prompt for the AI to generate an image'
					}
					size={{ m: 0, width: '100%' }}
					value={form.prompt}
					onChange={e => setForm(prev => ({ ...prev, prompt: e.target.value }))}
					disabled={form.mediaType === 'video'}
				/>

				<Select
					name="Variants"
					label="Number of Variants"
					helperText={
						form.mediaType === 'video'
							? 'Select the number of different videos to generate'
							: 'Select the number of different images to generate'
					}
					size={{ m: 0, width: '100%' }}
					options={variantOptions}
					value={form.variants}
					onChange={e => setForm(prev => ({ ...prev, variants: e.target.value }))}
				/>

				<Select
					name="AspectRatio"
					label="Select Aspect Ratio"
					helperText={
						form.mediaType === 'video'
							? 'Video generation does not support aspect ratio'
							: 'Select the aspect ratio for the images'
					}
					size={{ m: 0, width: '100%' }}
					options={aspectRatioOptions}
					value={form.aspectRatio}
					onChange={e => setForm(prev => ({ ...prev, aspectRatio: e.target.value }))}
					disabled={form.mediaType === 'video'}
				/>

				<Select
					disabled={form.mediaType === 'image'}
					name="Offers"
					label="Select an Offer"
					helperText={`Select the offer that the AI will use to generate the
					${form.mediaType === 'video' ? 'video' : 'images'}`}
					size={{ m: 0, width: '100%' }}
					options={offerOptions}
					value={form.offer}
					onChange={e => setForm(prev => ({ ...prev, offer: e.target.value }))}
				/>

				{form.mediaType === 'video' && (
					<Select
						showEmptyValue
						name="Actor"
						label="Select an Actor"
						helperText="Select the actor that the AI will use for the video"
						size={{ m: 0, width: '100%' }}
						options={actorOptions}
						value={form.actor}
						onChange={e => setForm(prev => ({ ...prev, actor: e.target.value }))}
					/>
				)}
			</section>

			<div className="my-6 flex w-full items-center justify-center">
				<Button type="submit" variant="contained" disabled={disabledSubmit} onClick={handleGenerateClick}>
					{loading && <CircularProgress size={20} color="inherit" sx={{ mr: 1 }} />}
					Generate {form.mediaType === 'video' ? 'Video' : 'Images'}
				</Button>
			</div>

			<Dialog open={modalOpen} maxWidth="sm">
				<header className="border-b p-3 text-center text-3xl font-semibold">Generation in Progress</header>
				<section className="flex flex-col gap-4 px-10 py-6">
					{rejectedVideos > 0 && (
						<>
							<Alert severity="warning" className="items-center">
								Only {+form.variants - rejectedVideos} out of {form.variants} videos were generated. <br />
								{rejectedVideos} video(s) failed to generate.
							</Alert>
						</>
					)}
					<p>
						AI video generation can take 30-60 minutes. Your video(s) will be sent to the{' '}
						<strong>#maximus-video-proc</strong> slack channel once they're ready.
					</p>
				</section>
				<footer className="flex items-center justify-center gap-6 p-6">
					{rejectedVideos > 0 ? (
						<>
							<Button onClick={handleDialogConfirm}>Close</Button>
							<Button
								variant="contained"
								onClick={() => {
									generateVideos(rejectedVideos);
									setForm(prev => ({ ...prev, variants: rejectedVideos.toString() }));
									setRejectedVideos(0);
									setModalOpen(false);
								}}
							>
								Re-submit remaining {rejectedVideos} video(s)
							</Button>
						</>
					) : (
						<Button variant="contained" onClick={handleDialogConfirm}>
							I understand
						</Button>
					)}
				</footer>
			</Dialog>
		</div>
	);
}
