import AIGeneration from '@/Components/Pages/SocialDashboard/AIGeneration/';

function SocialDashboard() {
	return (
		<div className="manage-container">
			<AIGeneration />
		</div>
	);
}

export default SocialDashboard;
