import {
	conditionalProfitClass,
	formatCurrency,
	formatFloat,
	formatNumber,
	formatPercent,
} from '@/utils/formatNumbers';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

const typeFormatMapping = {
	number: (value: number) => formatNumber(value),
	float: (value: number) => formatFloat(value),
	currency: (value: number) => formatCurrency(value),
	percent: (value: number) => formatPercent(value),
};

export default function Glance({ data, dataTypes, dataNames }: { data: object; dataTypes: object; dataNames: object }) {
	const formattedData = useMemo(() => {
		const dataKeys = Object.keys(data);
		return dataKeys.map(key => {
			const value =
				dataTypes[key] && typeFormatMapping[dataTypes[key]] ? typeFormatMapping[dataTypes[key]](data[key]) : data[key];
			return { key, value };
		});
	}, [data, dataTypes]);

	return (
		<div className="grid w-full gap-x-5 gap-y-3 p-3 auto-fit-36 xl:auto-fit-40 2xl:auto-fit-52">
			{formattedData.map(item => {
				if (item.key === 'act') return null;
				return (
					<div key={item.key} className="flex flex-col font-medium">
						<div
							className={twMerge(
								'truncate border-b border-neutral-200 px-1 text-xl xl:text-2xl',
								conditionalProfitClass(item.key, item.value)
							)}
						>
							{item.value}
						</div>
						<div className="px-1 text-base/tight text-black/50">{dataNames[item.key]}</div>
					</div>
				);
			})}
		</div>
	);
}
