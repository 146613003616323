import {Alert, Snackbar} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {closeSnackbar} from "../../Redux/Slices/main";

export default function SnackBarComponent() {
	const {
		children,
		open,
		severity,
		key,
		duration,
	} = useAppSelector((state) => state.main.snackbar);
	const dispatch = useAppDispatch();

	const handleClose = () => {
		dispatch(closeSnackbar());
	}

	return (
		<>
			<Snackbar
				ClickAwayListenerProps={{
					onClickAway: () => {} // Overrides default behavior of closing on click away
				}}
				open={open}
				key={key}
				autoHideDuration={duration}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={handleClose}
					severity={severity}
					sx={{width: '100%'}}
					className="flex items-center justify-center"
				>
					{children}
				</Alert>
			</Snackbar>
		</>
	)
}
