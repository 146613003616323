import { useEffect } from 'react';
import { format } from 'date-fns';
import TaboolaDetails, {
	modifyOSTargetingValue as modifyOSTargetingValue_T,
} from '../../../AddCampaign/Pages/NetworkDetails/TaboolaDetails.tsx';
import OutbrainDetails, {
	modifyOSTargetingValue as modifyOSTargetingValue_O,
} from '../../../AddCampaign/Pages/NetworkDetails/OutbrainDetails.tsx';
import RevContentDetails, {
	modifyOSTargetingValue as modifyOSTargetingValue_RC,
} from '../../../AddCampaign/Pages/NetworkDetails/RevContentDetails.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { isOutbrain, isRevContent, isTaboola, Network } from '@/utils/network-utils.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import FormLoader from '../../../../Loaders/FormLoader.tsx';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import '../../../AddCampaign/addCampaign.css';
import { formatStringDate } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';

export default function CampaignSettings() {
	const dispatch = useAppDispatch();
	const { campaignID, network, settingsDialogLoading } = useAppSelector(state => state.campaignDetails);
	const { userID } = useAppSelector(state => state.main);
	const { startDate, endDate, weekDay } = useAppSelector(state => state.filters);

	useEffect(() => {
		getData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getData = async () => {
		dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: true }));
		networkRequest(
			'api/v1/campaign/settings/pull',
			{
				campaignID,
				network,
				userID,
				weekDay,
				startDate: format(new Date(startDate), 'yyyy-MM-dd'),
				endDate: format(new Date(endDate), 'yyyy-MM-dd'),
			},
			'POST'
		)
			.then(response => response.json())
			.then(response => {
				if (response && typeof response === 'object') {
					setSettings(response);
				}
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			})
			.catch(() => {
				dispatch(changeCampaignDetails({ key: 'settingsDialogLoading', value: false }));
			});
	};

	const platformTargetingHasAllValues = (values: string[]) =>
		['1', '2', '3'].every((val: string) => values.includes(val));

	const setSettings = response => {
		const set = (name, value) => dispatch(changeSettingsField({ name, value }));
		const modifyOSFuncMap = new Map([
			[Network.Taboola, modifyOSTargetingValue_T],
			[Network.RevContent, modifyOSTargetingValue_RC],
			[Network.Outbrain, modifyOSTargetingValue_O],
		]);

		const modifyOSTargetingValue = modifyOSFuncMap.get(network)!;
		set('campaignName', response.name || '');
		set('isAgencyCampaign', response.isAgencyCampaign || false);
		set('isOttoCampaign', response.isOtto || false);
		set('budget', response.budget || 0);
		set('cpc', response.cpc || 0);
		set('brandingText', response.brandingText || '');
		set('conversionCapThreshold', response.conversionCap || '0');
		set('conversionCapTimeframe', response.timeframe || '0');
		set('creativeTrafficAllocation', response.trafficAllocationMode || '1');
		set(
			'platformTargeting',
			!response.platformTargeting?.length || platformTargetingHasAllValues(response.platformTargeting)
				? ['0']
				: response.platformTargeting
		);
		set('browserTargeting', response.browserTargeting?.length ? response.browserTargeting : ['0']);
		set(
			'osTargeting',
			response.osTargeting?.length ? modifyOSTargetingValue(response.platformTargeting, response.osTargeting) : ['0']
		);
		set('connectionType', response.connectionType || '0');
		set('marketingObjective', response.marketingObjective || '1');
		set('bidStrategy', response.bidStrategy || '1');
		set('optimizationType', response.bidStrategy || '0');
		set('conversionEvent', response.conversionEvent || '0');
		set('targetCPA', response.targetCPA || '1');
		set('targetROAS', response.targetROAS || '0');
		set('usmID', response.usmID || '1');
		set('subAccountId', response.subAccountId || '0');
		set('vertical', response.vertical || 'auto');
		set('affiliate', response.affiliate || '');
		set('s1', response.s1 || '');
		set('language', response.language || '1');
		set('startDate', response.startDate ? formatStringDate(response.startDate) : '');
		set('startDateOriginal', response.startDate ? formatStringDate(response.startDate) : '');
		set('countryTargeting', response.countryTargeting?.length ? response.countryTargeting : ['US']);
		set('excludeAdblockTraffic', response.excludeAdblockTraffic || false);
		set('highImpactTargeting', response.highImpactTargeting || false);
		set('MSNOnlyTargeting', response.MSNOnlyTargeting || false);
	};

	if (settingsDialogLoading) return <FormLoader />;

	if (isTaboola(network)) return <TaboolaDetails campaignDetails />;
	if (isRevContent(network)) return <RevContentDetails campaignDetails />;
	if (isOutbrain(network)) return <OutbrainDetails campaignDetails />;

	return <></>;
}
