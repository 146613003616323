
export default function RightArrow({className}: {className?: string}) {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			height="48"
			viewBox="0 -960 960 960"
			width="48"
		>
			<path xmlns="http://www.w3.org/2000/svg" d="m480-160-42-43 247-247H160v-60h525L438-757l42-43 320 320-320 320Z"/>
		</svg>
	)
}
