import { avg } from './Average';
import { sum } from './Sum';
import { blankColumn } from './BlankColumn';
import { profitPercent } from './ProfitPercent';
import { rpm } from '@/Components/DataTable/AggregationFunctions/Rpm.ts';
// import GrandTotalsTitle from './GrandTotalsTitle';

const CUSTOM_AGGREGATION_FUNCTIONS = {
	avg: avg,
	sum: sum,
	blankColumn: blankColumn,
	profitPercent: profitPercent(),
	maximusProfitPercent: profitPercent(true),
	rpm: rpm(),
	maximusRpm: rpm(true),
	// grandTotalsTitle: GrandTotalsTitle,
};

export default CUSTOM_AGGREGATION_FUNCTIONS;
