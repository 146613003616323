import { useAppSelector } from '@/hooks.ts';
import Download from '@/Icons/Download.tsx';
import { Button } from '@mui/material';
import { useMemo } from 'react';

async function downloadImage(imageSrc: string) {
	const image = await fetch(imageSrc);
	const imageBlog = await image.blob();
	const imageURL = URL.createObjectURL(imageBlog);

	const link = document.createElement('a');
	link.href = imageURL;
	link.download = 'AI_Generated_Image';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
}

export default function Display() {
	const { aiGeneratedImages } = useAppSelector(state => state.social);

	const renderedImages = useMemo(
		() =>
			aiGeneratedImages.map((image, index) => (
				<div key={index} className="relative">
					<Button
						onClick={() => downloadImage(image.image)}
						variant="contained"
						className="absolute bottom-4 right-6 min-w-0 rounded-full p-2 opacity-90"
					>
						<Download className="size-7 fill-white" />
					</Button>
					<img className="rounded-md shadow-md" src={image.image} alt={`Generated Image ${index}`} />
				</div>
			)),
		[aiGeneratedImages]
	);

	if (!renderedImages?.length) return null;

	return (
		<section className="grid gap-6 rounded-lg border-2 border-solid border-gray-300 p-4 text-center shadow-md auto-fit-[420px]">
			{renderedImages}
		</section>
	);
}
