import './CampaignDetails.css';

import { useAppSelector } from '@/hooks';
import { twMerge } from 'tailwind-merge';
import Charts from './Charts';
import Glance from './Glance';
import Menu from './Menu';
import Reports from './Reports';
import Settings from './Settings';
import { useCampaignDetails } from './useCampaignDetails';
import NotificationStripe from './utils/NotificationStripe';

export default function CampaignDetails() {
	useCampaignDetails();
	const sidebarExpanded = useAppSelector(state => state.main.sidebar.expanded)

	return (
		<div
			className={twMerge(
				'grid h-full w-full overflow-hidden transition-all',
				sidebarExpanded ? 'grid-cols-[256px_1fr]' : 'grid-cols-[0px_1fr]'
			)}
		>
			<Menu />
			<div className="flex h-full w-full flex-col gap-3 overflow-y-scroll px-2 py-3 min-w-[300px]">
				<NotificationStripe />
				<Glance />
				<Charts />
				<Reports />
				<Settings />
			</div>
		</div>
	);
}
