import { AddCampaignState } from '@/Redux/Slices/addCampaign.ts';
import SectionTitle from '@/Components/Pages/AddCampaign/Components/SectionTitle.tsx';
import Toggle from '@/Components/FormComponents/Toggle.tsx';
import Input from '@/Components/Pages/AddCampaign/Fields/Input.tsx';
import ChipSelect from '@/Components/Pages/AddCampaign/Fields/ChipSelect.tsx';
import DatePicker from '@/Components/Pages/AddCampaign/Fields/DatePicker.tsx';
import { countryTargetingOptions } from '@/Components/Pages/AddCampaign/Pages/constants.ts';
import Select from '@/Components/Pages/AddCampaign/Fields/Select.tsx';
import {
	checkPastDateDisable,
	checkPastDateEdit,
	formatStringDate,
	handleChipSelect,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';
import {
	conversionCapOptions,
	verticalOptions,
} from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/consts.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { AppDispatch } from '@/Redux/reduxStore.ts';
import { format } from 'date-fns';

type ElementFields = {
	settings: AddCampaignState['settings'];
	updateSettings: <K extends keyof AddCampaignState['settings']>(
		name: K,
		value: AddCampaignState['settings'][K]
	) => void;
	errors: AddCampaignState['errors'];
};

export const sectionTitleNetworkDetails = (name: string, settings: AddCampaignState['settings'], updateSettings) => (
	<SectionTitle
		title={name}
		EndAddition={
			<div>
				<Toggle
					name="is-otto"
					label="OTTO Campaign"
					trackColor="#4285F4"
					value={settings.isOttoCampaign}
					onChange={checked => updateSettings('isOttoCampaign', checked)}
				/>
				<Toggle
					name="is-agency"
					label="Agency Campaign"
					trackColor="#4285F4"
					value={settings.isAgencyCampaign}
					onChange={checked => updateSettings('isAgencyCampaign', checked)}
				/>
			</div>
		}
	/>
);

export const campaignNameField = ({ settings, updateSettings, errors }: ElementFields) => (
	<Input
		onChange={({ target: { value } }) => updateSettings('campaignName', value)}
		label="Campaign Name"
		value={settings.campaignName}
		name="campaign-name"
		error={!!errors.fields.campaignName}
		helperText={errors.fields.campaignName}
	/>
);

export const siteRuleSetField = ({ settings, updateSettings, errors }: ElementFields) => (
	<Input
		onChange={({ target: { value } }) => updateSettings('siteRuleSet', value)}
		label="Site Rule Set"
		value={settings.siteRuleSet}
		name="site-rule-set"
		error={!!errors.fields.siteRuleSet}
		helperText={errors.fields.siteRuleSet}
	/>
);

export const budgetField = ({ settings, updateSettings, errors }: ElementFields) => (
	<Input
		onChange={({ target: { value } }) => updateSettings('budget', value)}
		label="Budget"
		value={settings.budget}
		name="campaign-budget"
		numeric
		startAdornment={<span>$</span>}
		error={!!errors.fields.budget}
		helperText={errors.fields.budget}
	/>
);

export const countryTargetingField = ({ settings, updateSettings, errors }: ElementFields) => (
	<ChipSelect
		onChange={({ target: { value } }) => {
			const newValue = value?.length ? value : ['all'];
			updateSettings('countryTargeting', handleChipSelect(newValue, settings.countryTargeting));
		}}
		label="Country Targeting"
		value={settings.countryTargeting}
		name="country-targeting"
		options={countryTargetingOptions}
		aggregateAllOptions
		error={!!errors.fields.countryTargeting}
		helperText={errors.fields.countryTargeting}
	/>
);

export const conversionCapFields = ({ settings, updateSettings, errors }: ElementFields) => (
	<>
		<Select
			onChange={({ target: { value } }) => updateSettings('conversionCapTimeframe', value)}
			label="Conversion Cap Timeframe"
			value={settings.conversionCapTimeframe}
			name="conversion-cap-timeframe"
			options={conversionCapOptions}
			error={!!errors.fields.conversionCapTimeframe}
			helperText={errors.fields.conversionCapTimeframe}
		/>
		<Input
			onChange={({ target: { value } }) => updateSettings('conversionCapThreshold', value)}
			label="Conversion Cap Threshold"
			value={settings.conversionCapThreshold}
			name="conversion-cap-threshold"
			numeric
			disabled={settings.conversionCapTimeframe === '0'}
			error={!!errors.fields.conversionCapThreshold}
			helperText={errors.fields.conversionCapThreshold}
		/>
	</>
);

export const ottoSection = ({ settings, updateSettings, errors }: ElementFields) =>
	settings.isOttoCampaign && (
		<>
			<div className="mb-3" />
			<SectionTitle title="OTTO Data" />
			<div className="add-campaign-field-row">
				<Select
					onChange={({ target: { value } }) => updateSettings('vertical', value)}
					label="Vertical"
					value={settings.vertical}
					name="vertical"
					options={verticalOptions}
					error={!!errors.fields.vertical}
					helperText={errors.fields.vertical}
				/>
				<Input
					onChange={({ target: { value } }) => updateSettings('affiliate', value)}
					label="Affiliate ID"
					value={settings.affiliate}
					name="affiliate"
					error={!!errors.fields.affiliate}
					helperText={errors.fields.affiliate}
				/>
				<Input
					onChange={({ target: { value } }) => updateSettings('s1', value)}
					label="S1"
					value={settings.s1}
					name="s1"
					error={!!errors.fields.s1}
					helperText={errors.fields.s1}
				/>
			</div>
		</>
	);

export const splitByDeviceToggle = ({ settings, updateSettings }: ElementFields) => (
	<div className="ignore-act-padding">
		<Toggle
			label="Create a Campaign Per Platform"
			trackColor="#4285F4"
			value={settings.splitByDevice}
			onChange={checked => updateSettings('splitByDevice', checked)}
			name="split-by-device"
		/>
	</div>
);

export const cpcField = ({ settings, updateSettings, errors }: ElementFields) => (
	<Input
		onChange={({ target: { value } }) => updateSettings('cpc', value)}
		label="Campaign CPC"
		value={settings.cpc}
		numeric
		name="campaign-cpc"
		startAdornment={<span>$</span>}
		error={!!errors.fields.cpc}
		helperText={errors.fields.cpc}
	/>
);

export const startDateField = (
	{ settings, updateSettings, errors }: ElementFields,
	campaignDetails: boolean,
	dispatch: AppDispatch
) => (
	<DatePicker
		onChange={(value: Date | null) => {
			const formattedDate = format(value || new Date(), 'yyyy-MM-dd');
			if (checkPastDateEdit(formattedDate)) {
				dispatch(
					openSnackbar({
						children: 'Start Date can not be in the past.',
						severity: 'error',
					})
				);
				return;
			}
			updateSettings('startDate', formattedDate);
		}}
		label="Start Date"
		value={settings.startDate}
		name="start-date"
		error={!!errors.fields.startDate}
		helperText={errors.fields.startDate}
		disabled={campaignDetails && checkPastDateDisable(settings.startDateOriginal)}
		disablePastDates
	/>
);

export const brandingTextField = ({ settings, updateSettings, errors }: ElementFields) => (
	<Input
		onChange={({ target: { value } }) => updateSettings('brandingText', value)}
		label="Branding Text"
		value={settings.brandingText}
		name="branding-text"
		error={!!errors.fields.brandingText}
		helperText={errors.fields.brandingText}
	/>
);
