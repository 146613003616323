import { FreeTextChipSelect } from '@/Components/FormComponents/FreeTextChipSelect';
import { useUpdateCreatives } from '@/Components/Pages/AddCreatives/useUpdateCreatives.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import Check from '@/Icons/Check';
import Close from '@/Icons/Close';
import Copy from '@/Icons/Copy';
import Sparkles from '@/Icons/Sparkles.tsx';
import Warning from '@/Icons/Warning';
import { store } from '@/Redux/reduxStore';
import { type Ad, changeAd, changeNonField } from '@/Redux/Slices/addCampaign.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { isRevContent, networkMap } from '@/utils/network-utils.ts';
import { tokenMap } from '@/utils/token-utils';
import { Checkbox, Chip, CircularProgress, FormControlLabel, Tooltip } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import Input from '../../Fields/Input';
import Select from '../../Fields/Select';
import AiRating from './AiRating.tsx';
import { getCallToActionOptions } from './CallToActionOptions.tsx';

type AdProps = {
	ad: Ad;
	index: number;
	groupOptions?: { value: string; label: string }[];
	isCreative?: boolean;
	viewOnly?: boolean;
	manuallyEdited?: boolean;
	onAdChange?: (key: string, value: any) => void;
	onEditSuccess?: () => void;
	onCancelEdit?: () => void;
};

export default function Ad({
	ad,
	index,
	groupOptions,
	isCreative = false,
	viewOnly = false,
	manuallyEdited = false,
	onAdChange,
	onEditSuccess,
	onCancelEdit,
}: AdProps) {
	const dispatch = useAppDispatch();
	const errors = useAppSelector(state => state.addCampaign.errors);
	const outbrainVideoAds = useAppSelector(state => state.addCampaign.outbrainVideoAds);
	const adsSnapshot = useAppSelector(state => state.addCampaign.adsSnapshot);
	const storeNetwork = useAppSelector(state => state.campaignDetails.network);
	const network = ad.network || storeNetwork;
	const [editMode, setEditMode] = useState(!viewOnly);
	const { isSavingCreatives, saveCreatives } = useUpdateCreatives();
	const callToActionOptions = getCallToActionOptions(network);

	const initialState = useMemo(() => {
		return adsSnapshot.find(({ uuid }) => uuid === ad.uuid)!;
	}, [ad.uuid, adsSnapshot]);

	const disableEditingCreativeTemplate = !ad.addToCreatives && !isCreative && !viewOnly && !!ad.creativeId;

	const onChange = (key: string, value: any) => {
		onAdChange?.(key, value);
		dispatch(changeAd({ index, data: { [key]: value } }));
		const newErrors = structuredClone(errors);
		if (newErrors.fields[`${key}-${ad.uuid}`]) {
			delete newErrors.fields[`${key}-${ad.uuid}`];
		}
		dispatch(changeNonField({ key: 'errors', value: newErrors }));
	};

	const onDelete = () => {
		const newAds = structuredClone(store.getState().addCampaign.ads);
		const filtered = newAds.filter(({ uuid }) => uuid !== ad.uuid);
		dispatch(changeNonField({ key: 'ads', value: filtered }));
	};

	const copyText = async (text: string) => {
		try {
			await navigator.clipboard.writeText(text);
			dispatch(openSnackbar({ children: 'Copied to clipboard!', severity: 'success' }));
		} catch (err) {
			dispatch(openSnackbar({ children: 'Text could not be copied', severity: 'error' }));
		}
	};

	const Thumbnail = useMemo(
		() => (ad.fileType === 'video' && !ad.image.includes('.gif') ? 'video' : 'img'),
		[ad.fileType, ad.image]
	);

	useEffect(() => {
		if ((ad.addToCreatives || isCreative) && editMode && groupOptions?.length === 2) {
			onChange('usmID', groupOptions[1].value || '0');
		}
	}, [ad.addToCreatives, groupOptions, isCreative]);

	useEffect(() => {
		if (disableEditingCreativeTemplate) {
			const newAdData = {
				headline: initialState.headline,
				description: initialState.description,
				callToAction: initialState.callToAction,
				usmID: initialState.usmID,
				keywords: initialState.keywords,
			};
			dispatch(changeAd({ index, data: newAdData }));
		}
	}, [disableEditingCreativeTemplate]);

	return (
		<div
			className="relative flex w-full flex-col rounded-md border border-black/20 p-3 shadow-md"
			style={isSavingCreatives ? { opacity: 0.7, pointerEvents: 'none' } : {}}
		>
			{!viewOnly && (
				<div
					className="absolute -top-3 right-[-13px] flex cursor-pointer items-center justify-center rounded-full bg-red-500 p-0.5 shadow transition hover:bg-red-600"
					id={`display-ad-delete-${index}`}
					onClick={onDelete}
				>
					<Close className="size-5 fill-white" />
				</div>
			)}

			{viewOnly && manuallyEdited && (
				<Tooltip title="This item was edited manually. A new copy of it will be created on save with the new changes">
					<div className="absolute -top-3 right-[-13px] flex items-center justify-center rounded-full bg-blue-500 p-1 shadow transition hover:bg-blue-600">
						<Warning className="size-4 fill-white" />
					</div>
				</Tooltip>
			)}

			<Thumbnail
				autoPlay
				loop
				muted
				controls
				src={ad.image}
				alt="ad"
				className="max-h-[340px] rounded object-contain"
			/>

			{!!ad.dicerFile && (
				<Tooltip title="This image was AI generated">
					<div className="group absolute right-5 top-5 flex items-center justify-center rounded-full bg-green-200/70 p-1 hover:bg-green-300/60">
						<Sparkles className="size-4 stroke-green-900" />
					</div>
				</Tooltip>
			)}

			{editMode ? (
				<div className="mt-3 space-y-3">
					<div className="relative flex flex-col">
						<Input
							onChange={({ target: { value } }) => onChange('headline', value)}
							label="Headline"
							value={ad.headline}
							size={{ width: '100%' }}
							inputSize="small"
							name={`headline-edit-${index}`}
							maxSize={tokenMap[network].maxLength.headline}
							error={!!errors.fields[`headline-${ad.uuid}`]}
							helperText={errors.fields[`headline-${ad.uuid}`]}
							disabled={disableEditingCreativeTemplate}
							multiline
						/>
					</div>

					{!outbrainVideoAds && (
						<div className="relative flex flex-col">
							<Input
								onChange={({ target: { value } }) => onChange('description', value)}
								label="Description"
								value={ad.description}
								size={{ width: '100%' }}
								inputSize="small"
								name={`description-edit-${index}`}
								maxSize={tokenMap[network].maxLength.description}
								error={!!errors.fields[`description-${ad.uuid}`]}
								helperText={errors.fields[`description-${ad.uuid}`]}
								disabled={disableEditingCreativeTemplate}
								multiline
							/>
						</div>
					)}

					{!isRevContent(network) && ad.fileType !== 'video' && (
						<div className="relative flex flex-col">
							<Select
								onChange={({ target: { value } }) => onChange('callToAction', value)}
								label="Call To Action"
								value={ad.callToAction}
								name={`add-campaign-cta-edit-${index}`}
								options={callToActionOptions}
								size={{ width: '100%' }}
								disabled={disableEditingCreativeTemplate}
								inputSize="small"
							/>
						</div>
					)}

					{!isCreative && (
						<Tooltip title="If checked, a new Creative will be created on submit with this ad's data">
							<FormControlLabel
								label="Save as a new Creative"
								classes={{ label: 'text-sm' }}
								control={
									<Checkbox
										size="small"
										className="-ml-1.5 p-1"
										checked={!!ad.addToCreatives}
										onChange={() => onChange('addToCreatives', !ad.addToCreatives)}
									/>
								}
							/>
						</Tooltip>
					)}

					{!!(ad.addToCreatives || isCreative) && (
						<>
							{groupOptions?.length > 2 && (
								<Select
									inputSize="small"
									label="Maximus Group"
									size={{ width: '100%' }}
									disabled={!groupOptions}
									error={!!errors.fields[`usmID-${ad.uuid}`]}
									helperText={errors.fields[`usmID-${ad.uuid}`]}
									options={groupOptions || []}
									value={ad.usmID || '0'}
									onChange={e => onChange('usmID', e.target.value)}
								/>
							)}

							<FreeTextChipSelect
								label="Keywords"
								value={ad.keywords || []}
								onValueChange={newValue => onChange('keywords', newValue)}
							/>
						</>
					)}
				</div>
			) : (
				<div>
					<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Headline</label>
					<p className="text-sm leading-tight">{ad.headline}</p>

					{!!ad.description && (
						<>
							<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Description</label>
							<p className="text-sm leading-tight">{ad.description}</p>
						</>
					)}

					{!isRevContent(network) && (
						<>
							<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Call to Action</label>
							<p className="text-sm leading-tight">
								{callToActionOptions.find(opt => ad.callToAction === opt.value)?.label}
							</p>
						</>
					)}

					<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Network</label>
					<p className="text-sm leading-tight">{networkMap.get(network)}</p>

					<label className="mt-3 flex border-b text-xs font-semibold text-black/60">Keywords</label>
					<p className="text-sm leading-tight">{ad.keywords?.join(', ')}</p>
				</div>
			)}

			<div className="mt-auto text-xs leading-none text-black/70">
				{ad.createdAt && (
					<div className="mt-4">
						<b className="font-medium">Created at:</b> {new Date(ad.createdAt).toLocaleString()}
					</div>
				)}
				{ad.creativeId && (
					<div className="mt-1.5">
						<b className="font-medium">Creative ID:</b> {ad.creativeId}
						<button
							type="button"
							className="campaign-info-item-copy-button ml-1 h-4"
							onClick={() => copyText(ad.creativeId!)}
						>
							<Copy className="h-5 w-5" />
						</button>
					</div>
				)}
			</div>

			{!!ad.dicerFile && !viewOnly && <AiRating ad={ad} />}

			{viewOnly && (
				<div className="mt-3 flex w-full justify-center gap-3 fill-white">
					{editMode ? (
						<>
							<Chip
								icon={<Close className="size-5 pl-1" />}
								label={<span className="mx-1">Cancel</span>}
								color="error"
								size="small"
								disabled={isSavingCreatives || !manuallyEdited}
								onClick={() => {
									onDelete();
									onCancelEdit?.();
									setEditMode(false);
								}}
							/>
							<Tooltip title="Click to save as a new Creative">
								<Chip
									icon={isSavingCreatives ? <CircularProgress size={16} /> : <Check className="size-5 pl-1" />}
									label={<span className="mx-1">Create Duplicate</span>}
									color="info"
									size="small"
									disabled={isSavingCreatives}
									onClick={async () => {
										const success = await saveCreatives([ad.uuid], { randomizeNewIds: true });
										if (!success) return;
										onDelete();
										onCancelEdit?.();
										onEditSuccess?.();
										setEditMode(false);
									}}
								/>
							</Tooltip>
						</>
					) : (
						<Tooltip title="Click to edit a copy of this Creative item and save it as a duplicate">
							<Chip
								icon={<Copy className="size-5 pl-1" />}
								label={<span className="mx-1">Duplicate</span>}
								color="info"
								size="small"
								onClick={() => setEditMode(true)}
							/>
						</Tooltip>
					)}
				</div>
			)}
		</div>
	);
}
