import { useEffect, useMemo, useState } from 'react';
import List from '../../../../Icons/List';
import { updateMainStore, updateUserSettings } from '@/Redux/Slices/main.ts';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import SectionButton from './Components/SectionButton';
import SectionTitle from './Components/SectionTitle';
import { isRevContent } from '@/utils/network-utils.ts';

export default function ReportSection() {
	const dispatch = useAppDispatch();
	const report = useAppSelector(state => state.main.userSettings.detailsReport);
	const { advancedTracking, maximusLinks, network } = useAppSelector(state => state.campaignDetails);
	const [collapsed, setCollapsed] = useState(false);

	const buttons = useMemo(() => {
		return [
			{ label: 'Sites', key: 'Sites', isVisible: !isRevContent(network) },
			{ label: 'Widgets', key: 'Widgets', isVisible: isRevContent(network) },
			{ label: 'Images', key: 'Images', isVisible: true },
			{ label: 'All Images', key: 'AllImages', isVisible: true },
			{ label: `Image/${isRevContent(network) ? 'Widget' : 'Site'}/Presell`, key: 'ImageSitePresell', isVisible: true },
			{ label: 'Clicks', key: 'Clicks', isVisible: true },
			{ label: 'Conversions', key: 'Conversions', isVisible: true },
			{ label: 'States', key: 'States', isVisible: true },
			{ label: 'OS Targeting', key: 'OSTargeting', isVisible: true },
			{ label: 'Browsers', key: 'Browsers', isVisible: true },
			{ label: 'Landing', key: 'Landing', isVisible: maximusLinks && advancedTracking },
			{ label: 'Presells', key: 'Presells', isVisible: maximusLinks },
			{ label: 'Platforms', key: 'Platforms', isVisible: true },
			{ label: 'By Date', key: 'ByDate', isVisible: true },
			{ label: 'Campaign Log', key: 'CampaignLog', isVisible: true },
		];
	}, [maximusLinks, advancedTracking, network]);

	const updateReport = (newReport: string) => {
		if (report !== newReport) {
			dispatch(updateMainStore({ key: 'tableRows', value: [] }));
			dispatch(updateUserSettings({ key: 'detailsReport', value: newReport }));
			localStorage.setItem('userSettings_detailsReport', newReport);
		}
	};

	useEffect(() => {
		const visibleButtons = buttons.filter(btn => !!btn.isVisible);
		if (!visibleButtons.some(btn => btn.key === report)) {
			updateReport(visibleButtons[0].key);
		}
	}, [buttons, report]);

	return (
		<div className={`menu-section ${collapsed ? 'menu-section-collapsed' : ''}`}>
			<SectionTitle
				title="Reports"
				icon={<List className="menu-section-icon" />}
				onClick={() => setCollapsed(!collapsed)}
				collapsed={collapsed}
			/>
			{buttons.map(
				({ key, label, isVisible }) =>
					!!isVisible && (
						<SectionButton key={key} text={label} selected={report === key} onClick={() => updateReport(key)} />
					)
			)}
		</div>
	);
}
