import Sparkles from '@/Icons/Sparkles';
import { Tooltip, Zoom } from '@mui/material';
import { twMerge } from 'tailwind-merge';

export const ImageCell = ({ url, isAi = false }: { url: string; isAi?: boolean }) => {
	if (!url) return '';
	if (url.includes('.mp4') || url.includes('.webm') || url.includes('.mov')) {
		return (
			<Tooltip
				slotProps={{
					tooltip: {
						sx: {
							display: 'flex',
							position: 'relative',
							padding: 0,
							maxWidth: 'none',
							maxHeight: 'none',
							boxShadow: '0 1px 3px #0004, 0 3px 6px #0005',
						},
					},
				}}
				placement="right"
				TransitionComponent={Zoom}
				title={
					<>
						<video className="images-report-thumbnail-cell popover" src={url} autoPlay />
						{isAi && (
							<div className="absolute left-2 top-2 flex items-center gap-1 rounded-full bg-green-500/40 px-3 py-1 text-xs font-medium text-white">
								<Sparkles className="size-4" /> AI Generated
							</div>
						)}
					</>
				}
			>
				<video
					className={twMerge(
						'images-report-thumbnail-cell',
						isAi && 'border-2 border-solid border-transparent ring-2 ring-inset ring-green-500'
					)}
					src={url}
				/>
			</Tooltip>
		);
	}
	return (
		<Tooltip
			slotProps={{
				tooltip: {
					sx: {
						display: 'flex',
						position: 'relative',
						padding: 0,
						maxWidth: 'none',
						maxHeight: 'none',
						boxShadow: '0 1px 3px #0004, 0 3px 6px #0005',
					},
				},
			}}
			placement="right"
			TransitionComponent={Zoom}
			title={
				<>
					<img className="images-report-thumbnail-cell popover" src={url} />
					{isAi && (
						<div className="absolute left-2 top-2 flex items-center gap-1 rounded-full bg-green-500/40 px-3 py-1 text-xs font-medium text-white">
							<Sparkles className="size-4" /> AI Generated
						</div>
					)}
				</>
			}
		>
			<img
				className={twMerge(
					'images-report-thumbnail-cell',
					isAi && 'border-2 border-solid border-transparent ring-2 ring-inset ring-green-500'
				)}
				src={url}
			/>
		</Tooltip>
	);
};
