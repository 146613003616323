import { getFromLocalStorage } from '@/utils/local-storage-utils';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import PageLoader from '../../Loaders/PageLoader.tsx';

export default function Login() {
	const { loginWithRedirect } = useAuth0();

	useEffect(() => {
		let returnTo = getFromLocalStorage('previousRoute') || '/';
		if (returnTo.includes('login') || returnTo.includes('logout')) {
			returnTo = '/';
		}
		loginWithRedirect({ appState: { returnTo } });
	}, [loginWithRedirect]);

	return (
		<div className="h-screen w-screen bg-black">
			<PageLoader />
		</div>
	);
}
