import { useAppDispatch, useAppSelector } from '@/hooks';
import { openSnackbar } from '@/Redux/Slices/main';
import { creativesRequest } from '@/utils/networkRequest';
import { useState } from 'react';
import { useLocation } from 'react-router';

type AiItem = {
	dicerFile: string;
	image: string;
	headline: string;
};

export const Dicer = {
	teamId: 'cm3px1en30000yh74d6pr4s0i',
	offerId: {
		otto: 'cc12ff37aa700bf18f2f319a2',
		vibriance: 'cm4cw2c3j0003bacpbo88ld5l',
	},
};

export function useAiGenerate() {
	const dispatch = useAppDispatch();
	const location = useLocation();
	const network = useAppSelector(state => state.campaignDetails.network);
	const isOtto = useAppSelector(state => {
		if (location.pathname.includes('add_campaign')) {
			return state.addCampaign.settings.isOttoCampaign;
		}
		return state.campaignDetails.isOtto;
	});
	const [loading, setLoading] = useState(false);

	const fetchAiData = async (settings: {
		variants: number;
		descriptionsPrompt: string;
		headlinesPrompt: string;
		imagesPrompt: string;
		useAi: { images: boolean; headlines: boolean; descriptions: boolean };
		aspectRatio: '1:1' | '16:9' | '2:3' | '3:2' | '4:5' | '5:4' | '9:16' | '3:4' | '4:3';
		overrideOfferId?: string;
	}) => {
		setLoading(true);

		const calcOfferID = () => {
			if (settings.overrideOfferId) return settings.overrideOfferId;
			return isOtto ? Dicer.offerId.otto : Dicer.offerId.vibriance;
		};

		let aiResponse = { parsedResults: [] as AiItem[] };

		try {
			if (settings.useAi.images || settings.useAi.headlines) {
				const response = await creativesRequest('api/v1/campaign/ad/ai_generate/image', {
					teamId: Dicer.teamId,
					variants: settings.variants,
					userComments: settings.imagesPrompt || '',
					aspectRatio: settings.aspectRatio,
					imageStyle: 'Photo Realistic',
					withCopy: settings.useAi.headlines,
					offerId: calcOfferID(),
					network: network,
				});

				if (!response.ok) throw new Error('Failed to fetch');
				aiResponse = await response.json();

				if (settings.useAi.images && !aiResponse?.parsedResults?.length) {
					throw new Error('No images generated');
				}
			}
		} catch (error) {
			dispatch(openSnackbar({ children: 'Failed to pull AI data', severity: 'error' }));
		}

		const images: { dicerFile: string; image: string }[] = [];
		const headlines: string[] = [];
		const descriptions: string[] = [];

		if (aiResponse.parsedResults?.length) {
			aiResponse.parsedResults.forEach(item => {
				if (settings.useAi.images) {
					images.push({
						dicerFile: item.dicerFile,
						image: item.image,
					});
				}

				if (settings.useAi.headlines && item.headline) {
					headlines.push(item.headline);
				}
			});
		}

		setLoading(false);

		return { images, headlines, descriptions };
	};

	return {
		loading,
		fetchAiData,
	};
}
