import { GridColumnVisibilityModel } from '@mui/x-data-grid-premium';
import { isOutbrain, Network } from '@/utils/network-utils.ts';

export const visitsAndClicks = (
	estimatedSpends: boolean,
	advancedTracking: boolean,
	maximusLinks: boolean
): GridColumnVisibilityModel => {
	const showVisits = advancedTracking && estimatedSpends && maximusLinks;
	return { visits: showVisits, clicks: true };
};

export const conditionalCTR = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
	const showCTR = advancedTracking && maximusLinks;
	return { ctr: showCTR };
};

export const conditionalRPM = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
	const showRPM = advancedTracking && maximusLinks;
	return { rpm: showRPM };
};

export const conditionalEPV = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
	const showEPV = advancedTracking && maximusLinks;
	return { epv: showEPV };
};

export const conditionalPresellCTR = (advancedTracking: boolean, maximusLinks: boolean): GridColumnVisibilityModel => {
	const showCTR = advancedTracking && maximusLinks;
	return { presell_ctr: showCTR };
};

export const conditionalEditAdColumn = (network: Network): GridColumnVisibilityModel => {
	return { edit: !isOutbrain(network) };
};
