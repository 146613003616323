import PageLoader from '@/Components/Loaders/PageLoader';
import CampaignDetails from '@/Components/Pages/CampaignDetails';
import Login from '@/Components/Pages/Login';
import ManageCampaigns from '@/Components/Pages/ManageCampaigns';
import TopAds from '@/Components/Pages/TopAds';
import { useAppSelector } from '@/hooks';
import AddCampaign from '@/Pages/add_campaign';
import AddCreatives from '@/Pages/add_creatives';
import CloneCampaign from '@/Pages/clone_campaign';
import HelpDocs from '@/Pages/help_docs.tsx';
import Logout from '@/Pages/logout';
import VersionChecker from '@/Routing/VersionChecker';
import useAuth from '@/utils/useAuth';
import useInitializeApp from '@/utils/useInitializeApp';
import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router';

export const Router = () => {
	const { appLoaded } = useAppSelector(state => state.main);
	const { theme } = useAppSelector(state => state.main.userSettings);
	const location = useLocation();
	useAuth();
	useInitializeApp();

	useEffect(() => {
		document.body.className = theme;
	}, [theme]);

	useEffect(() => {
		if (location.pathname.includes('login') || location.pathname.includes('logout')) {
			localStorage.removeItem('previousRoute');
		} else {
			localStorage.setItem('previousRoute', location.pathname);
		}
	}, [location]);

	return !appLoaded ? (
		<PageLoader />
	) : (
		<>
			<Routes>
				<Route index element={<ManageCampaigns />} />
				<Route key="/campaign_details/:id" path="/campaign_details/:id" element={<CampaignDetails />} />
				<Route key="/login" path="/login" element={<Login />} />
				<Route key="/logout" path="/logout" element={<Logout />} />
				<Route key="/add_campaign_taboola" path="/add_campaign_taboola" element={<AddCampaign />} />
				<Route key="/add_campaign_revcontent" path="/add_campaign_revcontent" element={<AddCampaign />} />
				<Route key="/add_campaign_outbrain" path="/add_campaign_outbrain" element={<AddCampaign />} />
				<Route key="/clone_campaign_taboola/:id" path="/clone_campaign_taboola/:id" element={<CloneCampaign />} />
				<Route key="/clone_campaign_revcontent/:id" path="/clone_campaign_revcontent/:id" element={<CloneCampaign />} />
				<Route key="/creatives_gallery" path="/creatives_gallery" element={<AddCreatives />} />
				<Route key="/top_ads" path="/top_ads" element={<TopAds />} />
				<Route key="/clone_campaign_outbrain/:id" path="/clone_campaign_outbrain/:id" element={<CloneCampaign />} />
				<Route key="/help" path="/help/*" element={<HelpDocs />} />
			</Routes>

			<VersionChecker />
		</>
	);
};
