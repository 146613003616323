import { Alert } from '@mui/material';
import { Draft, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ComponentProps, ReactNode } from 'react';
import { getFromLocalStorage } from '../../utils/local-storage-utils';

export interface MainState {
	userID: string;
	userSettings: {
		theme: string;
		detailsReport: string;
		redirectOnRouting: boolean;
	};
	dialog: {
		children?: ReactNode;
		warningDialog?: boolean;
		cancelText?: string;
		formDialog?: boolean;
		confirmText?: string;
		title?: string;
		cancelAction?: boolean;
		successDialog?: boolean;
		open?: boolean;
		confirmDialog?: boolean;
	};
	dataGridCustomization: {
		columnOrder: object;
		hideColumns: object;
		filters: object;
		columnSizes: object;
		forceRefreshCount: number;
	};
	sidebar: {
		expanded: boolean;
	};
	snackbar: {
		severity: ComponentProps<typeof Alert>['severity'];
		children: ReactNode;
		open: boolean;
		key: number;
		duration: number;
	};
	token: string;
	tableRows: Array<object>;
	email: string;
	loggedIn: boolean;
	isAdminUser: boolean;
	isSocialUser: boolean;
	appLoaded: boolean;
	helpOverlay: {
		open: boolean;
	};
	version: string;
	branch: string;
}

interface ActionInterface {
	payload: {
		key?: string;
		value?: never;
		severity?: string;
		children?: ReactNode;
		title?: string;
		loading?: boolean;
		confirmDialog?: boolean;
		formDialog?: boolean;
		successDialog?: boolean;
		warningDialog?: boolean;
		confirmText?: string;
		cancelText?: string;
		cancelAction?: boolean;
	};
}

export const initialState: MainState = {
	userSettings: {
		theme: getFromLocalStorage('userSettings_theme') || 'light',
		detailsReport: getFromLocalStorage('userSettings_detailsReport') || 'Sites',
		redirectOnRouting: (getFromLocalStorage('userSettings_redirectOnRouting') || 'true') === 'true',
	},
	sidebar: {
		expanded: true,
	},
	snackbar: {
		open: false,
		children: '',
		severity: 'info',
		key: 0,
		duration: 5_000,
	},
	dialog: {
		open: false,
		title: '',
		children: '',
		confirmDialog: false,
		formDialog: false,
		successDialog: false,
		warningDialog: false,
		confirmText: 'Confirm',
		cancelText: 'Cancel',
		cancelAction: false,
	},
	helpOverlay: {
		open: false,
	},
	email: '',
	loggedIn: false,
	isAdminUser: false,
	isSocialUser: false,
	appLoaded: false,
	userID: '',
	token: '',
	tableRows: [],
	dataGridCustomization: {
		columnOrder: {},
		columnSizes: {},
		hideColumns: {},
		filters: {},
		forceRefreshCount: 0,
	},
	version: '',
	branch: import.meta.env.VITE_GIT_BRANCH || '',
};

const main = createSlice({
	name: 'main',
	initialState,
	reducers: {
		updateSidebar: (state, action: PayloadAction<Partial<MainState['sidebar']>>) => {
			state.sidebar = { ...state.sidebar, ...action.payload };
		},
		closeSnackbar: state => {
			state.snackbar.open = false;
		},
		openSnackbar: (
			state: MainState,
			action: { payload: { customDuration?: number } & Pick<MainState['snackbar'], 'severity' | 'children'> }
		) => {
			state.snackbar.key = new Date().getTime();
			state.snackbar.open = true;
			state.snackbar.children = action.payload.children;
			state.snackbar.severity = action.payload.severity;
			state.snackbar.duration = action.payload.customDuration || 5_000;
		},
		closeDialog: state => {
			state.dialog.open = false;
		},
		openDialog: (state, action: ActionInterface) => {
			state.dialog.open = true;
			state.dialog.title = action.payload.title;
			state.dialog.children = action.payload.children;
			state.dialog.confirmDialog = action.payload.confirmDialog;
			state.dialog.formDialog = action.payload.formDialog;
			state.dialog.successDialog = action.payload.successDialog;
			state.dialog.warningDialog = action.payload.warningDialog;
			state.dialog.confirmText = action.payload.confirmText;
			state.dialog.cancelText = action.payload.cancelText;
			state.dialog.cancelAction = action.payload.cancelAction;
		},
		closeHelpOverlay: state => {
			state.helpOverlay.open = false;
		},
		openHelpOverlay: state => {
			state.helpOverlay.open = true;
		},
		updateDataGridCustomization: (state, action: PayloadAction<Partial<MainState['dataGridCustomization']>>) => {
			state.dataGridCustomization = { ...state.dataGridCustomization, ...action.payload };
		},
		updateUserSettings: <K extends keyof MainState['userSettings']>(
			state: Draft<MainState>,
			action: PayloadAction<{ key: K; value: MainState['userSettings'][K] }>
		) => {
			state.userSettings[action.payload.key] = action.payload.value;
		},
		updateMainStore: (state, action: PayloadAction<{ key: keyof MainState; value: unknown }>) => {
			state[action.payload.key as string] = action.payload.value;
		},
		bulkUpdateMainStore: (state, action: PayloadAction<Partial<MainState>>) => {
			return { ...state, ...action.payload };
		},
	},
});

export const {
	updateSidebar,
	closeSnackbar,
	openSnackbar,
	closeDialog,
	openDialog,
	closeHelpOverlay,
	openHelpOverlay,
	updateDataGridCustomization,
	updateUserSettings,
	updateMainStore,
	bulkUpdateMainStore,
} = main.actions;

export default main.reducer;
