import { useMemo } from 'react';
import { commonColumns } from '@/utils/commonColumns.tsx';
import ReportTable from '../../../../../DataTable/ReportTable.tsx';
import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import { AppDispatch } from '@/Redux/reduxStore.ts';
import StateBlockCell from './StateBlockCell.tsx';
import { isRevContent, isTaboola } from '@/utils/network-utils.ts';
import { EstimatedReportsDisclaimer } from '@/Components/Pages/CampaignDetails/Reports/Collection/shared/helper.tsx';

export interface storeData {
	campaignID: string;
	network: number;
	tableRows: object[];
	blockingSubmitting: boolean;
}

const columns = (storeData: storeData, dispatch: AppDispatch) => {
	return [
		{
			field: 'state',
			headerName: 'State',
			width: 170,
			renderCell: (params: never) => (
				<span className={params.row.blocked ? 'cell-blocked-state-name' : ''}>{params.value}</span>
			),
		},
		...commonColumns([
			'visits',
			'impressions',
			'clicks',
			'conversions',
			'average_cpc',
			'epc',
			'ctr',
			'cvr',
			'cpa',
			'roas',
			'revenue',
			'spend',
			'profit',
			'profit_percent',
		]),
		{
			field: 'blocked',
			headerName: 'Blocking',
			width: 100,
			align: 'center',
			valueFormatter: (value: any) => (value === undefined ? '' : value ? 'Blocked' : 'Unblocked'),
			renderCell: (params: never) => StateBlockCell(params, storeData, dispatch),
		},
	];
};

export default function States() {
	const { estimatedSpends, campaignID, network, blockingSubmitting, advancedTracking, maximusLinks } = useAppSelector(
		state => state.campaignDetails
	);
	const { tableRows } = useAppSelector(state => state.main);
	const dispatch = useAppDispatch();

	const asyncColumns = useMemo(() => {
		return {
			visits: isRevContent(network) && advancedTracking && maximusLinks,
			impressions: !isRevContent(network),
			blocked: isTaboola(network),
		};
	}, [advancedTracking, maximusLinks, network]);

	return (
		<div className="campaign-details-report">
			{!estimatedSpends && isRevContent(network) && <EstimatedReportsDisclaimer />}
			<ReportTable
				columnVisibility={asyncColumns}
				columns={columns({ campaignID, network, blockingSubmitting, tableRows }, dispatch)}
				tableName="States"
				endpoint="api/v1/campaign/reporting/state"
				aggregationModel={{
					impressions: 'sum',
					visits: 'sum',
					clicks: 'sum',
					conversions: 'sum',
					epc: 'avg',
					average_cpc: 'avg',
					ctr: 'avg',
					cpa: 'avg',
					profit: 'sum',
					profit_percent: 'profitPercent',
					maximusProfit: 'sum',
					maximusProfitPercent: 'maximusProfitPercent',
					maximusClicks: 'sum',
					estimatedSpend: 'sum',
					maximusConversions: 'sum',
					maximusRevenue: 'sum',
					maximusRoas: 'avg',
					maximusCvr: 'avg',
					maximusCtr: 'avg',
					maximusCpa: 'avg',
					cvr: 'avg',
					roas: 'avg',
					spend: 'sum',
					revenue: 'sum',
					state: 'blankColumn',
					blocked: 'blankColumn',
				}}
			/>
		</div>
	);
}
