import { useAppDispatch, useAppSelector } from '@/hooks.ts';
import networkRequest from '../../../../../utils/networkRequest.ts';
import { ValidateStepProps } from '../../../AddCampaign/Components/StepsValidation.tsx';
import { changeNonField, changeSettingsField } from '@/Redux/Slices/addCampaign.ts';
import { openSnackbar } from '@/Redux/Slices/main.ts';
import { isOutbrain, isTaboola, Network } from '@/utils/network-utils.ts';
import { changeCampaignDetails } from '@/Redux/Slices/campaignDetails.ts';
import {
	validateCampaignSettings,
	budgetCPCTaboolaValidation,
} from '../../../AddCampaign/Components/Validation/Details.tsx';
import { changeFFStatus } from '../../Glance/helper.ts';
import { checkPastDateEdit } from '@/Components/Pages/AddCampaign/Pages/NetworkDetails/helpers/functions.ts';

export const useSubmitSettings = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network } = useAppSelector(state => state.campaignDetails);
	const { settings } = useAppSelector(state => state.addCampaign);

	return async (confirmedBudgetChange = false) => {
		const newBudget = Number(settings.cpc) * 30;
		if (confirmedBudgetChange) {
			dispatch(changeSettingsField({ name: 'budget', value: newBudget }));
		}
		const newErrors: ValidateStepProps = validateCampaignSettings(settings, network, true);
		dispatch(changeNonField({ key: 'errors', value: newErrors }));
		if (Object.keys(newErrors.fields).length) {
			return;
		}
		const cpcCheck = isOutbrain(network)
			? !['3', '4', '5'].includes(settings.optimizationType)
			: settings.bidStrategy === '1' || settings.bidStrategy === '2';
		return await networkRequest(
			'api/v1/campaign/settings/update',
			{
				campaignID,
				network,
				budget: Number(confirmedBudgetChange ? newBudget : settings.budget),
				cpc: cpcCheck ? settings.cpc : undefined,
				conversionCapTimeframe: settings.conversionCapTimeframe,
				conversionCapThreshold: settings.conversionCapTimeframe !== '0' ? settings.conversionCapThreshold || '0' : '0',
				isAgencyCampaign: settings.isAgencyCampaign,
				usmID: settings.usmID,
				osTargeting: settings.osTargeting,
				platformTargeting:
					settings.platformTargeting.length === 1 && settings.platformTargeting[0] === '0'
						? ['3', '2', '1']
						: settings.platformTargeting, // TODO: replace state transformation with `aggregateAllOptions` in ChipSelect component
				bidStrategy: isOutbrain(network) ? settings.optimizationType : settings.bidStrategy,
				conversionEvent: settings.conversionEvent,
				connectionType: settings.connectionType,
				browserTargeting: isTaboola(network) ? settings.browserTargeting[0] : settings.browserTargeting,
				marketingObjective: settings.marketingObjective,
				brandingText: settings.brandingText,
				vertical: settings.vertical,
				affiliate: settings.affiliate,
				s1: settings.s1,
				isOtto: settings.isOttoCampaign,
				trafficAllocationMode: settings.creativeTrafficAllocation,
				targetCPA: settings.bidStrategy === '4' || settings.optimizationType === '4' ? settings.targetCPA : undefined,
				targetROAS: settings.optimizationType === '5' ? settings.targetROAS : undefined,
				name: settings.campaignName,
				language: settings.language,
				countryTargeting: settings.countryTargeting,
				startDate: checkPastDateEdit(settings.startDate) ? undefined : settings.startDate,
				excludeAdblockTraffic: settings.excludeAdblockTraffic,
				highImpactTargeting: settings.highImpactTargeting,
				MSNOnlyTargeting: settings.MSNOnlyTargeting,
			},
			'POST'
		)
			.then(response => response.json())
			.then(response => {
				if (response && typeof response === 'object' && response.success) {
					if (response.warnings?.length || response.confirms?.length) {
						return { warnings: response.warnings || [], confirms: response.confirms || [] };
					} else {
						dispatch({ type: 'campaignDetails/closeDialog' });
						dispatch(changeCampaignDetails({ key: 'updatedCampaignSettings', value: true }));
					}
				} else {
					dispatch(
						openSnackbar({
							children: response.message || 'Something went wrong. Please try again.',
							severity: 'error',
						})
					);
				}
			});
	};
};

export const useDisableFFStatusConfirm = () => {
	const dispatch = useAppDispatch();
	const { campaignID, network } = useAppSelector(state => state.campaignDetails);
	return async () => changeFFStatus(campaignID, network, dispatch);
};

export const checkConfirmBudget = ({
	budget,
	cpc,
	bidStrategy,
	network,
}: {
	budget?: string;
	cpc?: string;
	bidStrategy?: string;
	network: Network;
}) => {
	if (isTaboola(network) && budgetCPCTaboolaValidation(budget, cpc, bidStrategy)) {
		const newBudget = Number(cpc) * 30;
		return `Taboola requires a minimum budget of 30x the CPC and so we’d need to update the budget to $${newBudget} in order to process this change. Do you want to continue with this change by letting Maximus update your budget to $${newBudget} as well?`;
	}
	return '';
};
